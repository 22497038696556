import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vue3StarRatings from "vue3-star-ratings";
const app = createApp(App);
app.use(router);
app.use(store);
app.component("vue3-star-ratings", vue3StarRatings);
app.mount("#app");
