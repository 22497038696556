import axios from "axios";

const access_token = localStorage.getItem("access_token");
export const url = "https://obihayot.api-crud.uz/";

export const instance = axios.create({
  baseURL: url,
  headers: {
    Authorization: `Bearer ${access_token}`,
    Accept: "*/*",
  },
});

export const file = axios.create({
  baseURL: url,
  headers: {
    Authorization: `Bearer ${access_token}`,
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  },
});

// login

export function login(data) {
  return instance.post("token", data);
}

// user

export function user_get(page, limit, search, role) {
  if (search && !role) {
    return instance.get(
      `get_users?search=${search}&page=${page}&limit=${limit}`
    );
  } else if (role && !search) {
    return instance.get(`get_users?role=${role}&page=${page}&limit=${limit}`);
  } else if (search && role) {
    return instance.get(
      `get_users?search=${search}&role=${role}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_users?page=${page}&limit=${limit}`);
  }
}

export function current_user() {
  return instance.get("get_user");
}

export function user_add(data) {
  return instance.post("create_user", data);
}

export function user_put(data) {
  return instance.put("update_user/" + data.id, data);
}

// building

export function building_get(page, limit, search, status) {
  if (search && !status) {
    return instance.get(
      `get_buildings?search=${search}&page=${page}&limit=${limit}`
    );
  } else if (status && !search) {
    return instance.get(
      `get_buildings?status=${status}&page=${page}&limit=${limit}`
    );
  } else if (search && status) {
    return instance.get(
      `get_buildings?search=${search}&status=${status}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_buildings?page=${page}&limit=${limit}`);
  }
}

export function get_building_daily_info(page, limit, from_time, to_time, id) {
  const date =
    from_time && to_time ? `&from_time=${from_time}&to_time=${to_time}` : "";
  return instance.get(
    `get_building_daily_info?page=${page}&limit=${limit}&building_id=${id}${date}`
  );
}

export function building_add(data) {
  return instance.post("create_building", data);
}

export function building_put(data) {
  return instance.put("update_building/" + data.id, data);
}

export function building_commet(id) {
  return instance.put(`update_building_comment_status/${id}`);
}

// smeta

export function smeta_get(page, limit, search, type, status, id, order_by) {
  if (search && status && type && order_by)
    return instance.get(
      `get_smeta?building_id=${id}&search=${search}&status=${status}&type=${type}&order_by=${order_by}&page=${page}&limit=${limit}`
    );
  else if (search && status && type && !order_by)
    return instance.get(
      `get_smeta?building_id=${id}&search=${search}&status=${status}&type=${type}&page=${page}&limit=${limit}`
    );
  else if (search && status && !type && order_by)
    return instance.get(
      `get_smeta?building_id=${id}&search=${search}&status=${status}&order_by=${order_by}&page=${page}&limit=${limit}`
    );
  else if (search && !status && type && order_by)
    return instance.get(
      `get_smeta?building_id=${id}&search=${search}&type=${type}&order_by=${order_by}&page=${page}&limit=${limit}`
    );
  else if (!search && status && type && order_by)
    return instance.get(
      `get_smeta?building_id=${id}&status=${status}&type=${type}&order_by=${order_by}&page=${page}&limit=${limit}`
    );
  else if (search && status && !type && !order_by)
    return instance.get(
      `get_smeta?building_id=${id}&search=${search}&status=${status}&page=${page}&limit=${limit}`
    );
  else if (!search && !status && type && order_by)
    return instance.get(
      `get_smeta?building_id=${id}&type=${type}&order_by=${order_by}&page=${page}&limit=${limit}`
    );
  else if (!search && status && !type && order_by)
    return instance.get(
      `get_smeta?building_id=${id}&status=${status}&order_by=${order_by}&page=${page}&limit=${limit}`
    );
  else if (search && !status && type && !order_by)
    return instance.get(
      `get_smeta?building_id=${id}&search=${search}&type=${type}&page=${page}&limit=${limit}`
    );
  else if (!search && status && type && !order_by)
    return instance.get(
      `get_smeta?building_id=${id}&status=${status}&type=${type}&page=${page}&limit=${limit}`
    );
  else if (search && !status && !type && !order_by)
    return instance.get(
      `get_smeta?building_id=${id}&search=${search}&page=${page}&limit=${limit}`
    );
  else if (!search && status && !type && !order_by)
    return instance.get(
      `get_smeta?building_id=${id}&status=${status}&page=${page}&limit=${limit}`
    );
  else if (!search && !status && type && !order_by)
    return instance.get(
      `get_smeta?building_id=${id}&type=${type}&page=${page}&limit=${limit}`
    );
  else if (!search && !status && !type && order_by)
    return instance.get(
      `get_smeta?building_id=${id}&order_by=${order_by}&page=${page}&limit=${limit}`
    );
  else
    return instance.get(
      `get_smeta?building_id=${id}&page=${page}&limit=${limit}`
    );
}

export function smeta_request_get(page, limit, search, status, id, user_id) {
  if (search && !status) {
    return instance.get(
      `get_smeta_for_supply/?building_id=${id}&user_id=${user_id}&search=${search}&page=${page}&limit=${limit}`
    );
  } else if (status && !search) {
    return instance.get(
      `get_smeta_for_supply/?building_id=${id}&user_id=${user_id}&status=${status}&page=${page}&limit=${limit}`
    );
  } else if (search && status) {
    return instance.get(
      `get_smeta_for_supply/?building_id=${id}&user_id=${user_id}&search=${search}&status=${status}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `get_smeta_for_supply/?building_id=${id}&user_id=${user_id}&&page=${page}&limit=${limit}`
    );
  }
}

export function accept_smeta_request(user_id, data) {
  return instance.put(`attach_to_user/${user_id}`, data);
}

export function remove_smeta_request(id) {
  return instance.delete(`remove_attached_product/${id}`);
}

export function smeta_add(data, id) {
  return file.post("create_smeta/" + id, data);
}

export function smeta_remove(id, status) {
  if (status !== "smeta_id") {
    return instance.delete(`remove_smeta?building_id=${id}`);
  } else {
    return instance.delete(`remove_smeta?smeta_id=${id}`);
  }
}

export function take_supply_smeta(data) {
  if (data[0].type == "smeta") {
    return instance.post(`take_supply_by_b_a`, data);
  } else {
    return instance.post(`take_supply_smeta`, data);
  }
}

export function create_proche_sorov(data, id) {
  return file.post(`create_proche_sorov/${id}`, data);
}

// products

export function products_get(page, limit, search) {
  if (search) {
    return instance.get(
      `get_products?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_products?page=${page}&limit=${limit}`);
  }
}

export function product_add(user_id, data) {
  return instance.post(`product_request?prorap_id=${user_id}`, data);
}
// foremans

export function foreman_get(id, user_id) {
  return instance.get(`get_foremans?building_id=${id}&user_id=${user_id}`);
}

export function foreman_add(data) {
  return instance.post("create_foreman", data);
}

export function foreman_remove(id) {
  return instance.delete("remove_foreman/" + id);
}

// warehousemans

export function warehouseman_get(id) {
  return instance.get("get_warehousemans?building_id=" + id);
}

export function warehouseman_add(data) {
  return instance.post("create_warehouseman", data);
}

export function warehouseman_remove(id) {
  return instance.delete("remove_warehouseman/" + id);
}

// product composition

export function product_composition_get(id) {
  return instance.get("get_product_composition/" + id);
}

export function product_composition_add(data) {
  return instance.post("create_product_composition", data);
}

export function take_product_request(data) {
  if (data.type == "tarkib") {
    return instance.post("take_supply_by_b_a", Array(data));
  } else {
    return instance.post("take_supply_product_composition", data);
  }
}

export function product_composition_remove(id) {
  return instance.delete("remove_product_composition/" + id);
}

// bank accounts

export function bank_account_get(page, limit, search) {
  if (search) {
    return instance.get(
      `get_bank_accounts?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_bank_accounts?page=${page}&limit=${limit}`);
  }
}

export function bank_account_add(data) {
  return instance.post("create_bank_account", data);
}

export function bank_account_put(id, data) {
  return instance.put("update_bank_account/" + id, data);
}

// transfers

export function transfers(data) {
  return instance.post("acceptance_money_to_bank_account", data);
}

export function transfer_to_cashiers(data) {
  return instance.post("transfer_money_to_cashier", data);
}

export function transfer_history_get(
  page,
  limit,
  from,
  to,
  type,
  from_time,
  to_time
) {
  if (from_time && to_time) {
    return instance.get(
      `money_transfered_to_bank_accounts?from_time=${from_time}&to_time=${to_time}&from_=${from}&to_=${to}&type=${type}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `money_transfered_to_bank_accounts?from_=${from}&to_=${to}&type=${type}&page=${page}&limit=${limit}`
    );
  }
}

export function transfer_cashier_get(
  page,
  limit,
  from,
  to,
  status,
  from_time,
  to_time
) {
  if (from_time && to_time) {
    return instance.get(
      `money_to_cashier?from_time=${from_time}&to_time=${to_time}&from_=${from}&to_=${to}&status=${status}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `money_to_cashier?from_=${from}&to_=${to}&status=${status}&page=${page}&limit=${limit}`
    );
  }
}

export function transfer_users_get(
  page,
  limit,
  from,
  to,
  status,
  from_time,
  to_time
) {
  const time = from_time && to_time ? `&from_time=${from_time}&to_time=${to_time}` : ``
  return instance.get(
    `transfered_money_to_users_from_cashier?from_=${from}&to_=${to}&status=${status}&page=${page}&limit=${limit}${time}`
  );

}

export function transfer_m_cashiers_get(
  page,
  limit,
  from,
  to,
  status,
  from_time,
  to_time
) {
  if (from_time && to_time) {
    return instance.get(
      `transfered_money_to_cashier_from_users?from_time=${from_time}&to_time=${to_time}&from_=${from}&to_=${to}&status=${status}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `transfered_money_to_cashier_from_users?from_=${from}&to_=${to}&status=${status}&page=${page}&limit=${limit}`
    );
  }
}

export function accept_money_in_cashier(id, money_l_id, percent, comment) {
  return instance.put(
    `acceptance_or_remove_transfered_money/${id}?money_launderer_id=${money_l_id}&percent=${percent}&comment=${comment}`
  );
}

export function take_money_to_launderer(data) {
  return instance.post("take_money_from_money_launderer", data);
}

export function take_money_from_wallets(data) {
  return instance.post("transfer_money_from_wallet", data);
}

export function transfer_money_to_wallets(data) {
  return instance.post("transfer_money_to_wallet", data);
}

export function transfer_money_to_users(data) {
  return instance.post("transfer_money_to_users_from_cashier", data);
}

export function transfer_money_to_cashier(data) {
  return instance.post("transfer_money_to_cashier_from_users", data);
}

export function money_from_launderer_get(
  page,
  limit,
  from,
  from_time,
  to_time
) {
  if (from_time && to_time) {
    return instance.get(
      `money_from_money_launderer?from_time=${from_time}&to_time=${to_time}&from_=${from}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `money_from_money_launderer?from_=${from}&page=${page}&limit=${limit}`
    );
  }
}

export function money_to_launderer_get(page, limit, to, from_time, to_time) {
  if (from_time && to_time) {
    return instance.get(
      `money_to_money_launderer?from_time=${from_time}&to_time=${to_time}&to_=${to}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `money_to_money_launderer?to_=${to}&page=${page}&limit=${limit}`
    );
  }
}

export function transfer_from_w_history_get(
  page,
  limit,
  from,
  from_time,
  to_time
) {
  if (from_time && to_time) {
    return instance.get(
      `transfered_money_from_wallet?from_time=${from_time}&to_time=${to_time}&from_=${from}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `transfered_money_from_wallet?from_=${from}&page=${page}&limit=${limit}`
    );
  }
}

export function transfer_to_w_history_get(page, limit, to, from_time, to_time) {
  if (from_time && to_time) {
    return instance.get(
      `transfered_money_to_wallet?from_time=${from_time}&to_time=${to_time}&to_=${to}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `transfered_money_to_wallet?to_=${to}&page=${page}&limit=${limit}`
    );
  }
}

export function accept_or_remove_m_user(id) {
  return instance.put(
    `acceptance_or_remove_transfered_money_to_cashier_from_users/${id}`
  );
}
export function accept_or_remove_m_cashier(id) {
  return instance.put(
    `acceptance_or_remove_transfered_money_to_users_from_cashier/${id}`
  );
}

// expences

// expence fixed

export function fixed_expence_get(page, limit, search) {
  if (search) {
    return instance.get(
      `fixed_expenses?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`fixed_expenses?page=${page}&limit=${limit}`);
  }
}

export function fixed_expence_add(data) {
  return instance.post(`create_fixed_expense`, data);
}

export function fixed_expence_put(data) {
  return instance.put(`update_fixed_expense/${data.id}`, data);
}

export function get_fixed_history(
  page,
  limit,
  from_,
  to_,
  user_id,
  from_time,
  to_time,
  for_bugalter = false
) {
  if (from_time && to_time) {
    return instance.get(
      `get_fixed_expenses?to_=${to_}&from_=${from_}&user_id=${user_id}&from_time=${from_time}&to_time=${to_time}&page=${page}&limit=${limit}&for_bugalter=${for_bugalter}`
    );
  } else {
    return instance.get(
      `get_fixed_expenses?to_=${to_}&from_=${from_}&user_id=${user_id}&page=${page}&limit=${limit}&for_bugalter=${for_bugalter}`
    );
  }
}

// variable

export function get_variable_history(page, limit, from_, user_id) {
  return instance.get(
    `get_variable_expenses?from_=${from_}&user_id=${user_id}&page=${page}&limit=${limit}`
  );
}

export function pay_for_variable_expense(data) {
  return instance.post(`pay_for_variable_expense`, data);
}

export function pay_for_fixed_expense(data) {
  return instance.post(`pay_for_fixed_expense`, data);
}

// Building documents

export function building_d_get(id, type = "", from_time = "", to_time = "") {
  const date =
    from_time && to_time ? `from_time=${from_time}&to_time=${to_time}` : "";
  return instance.get(
    `get_building_documents/${id}?${type ? `type=${type}` : ""}&${date}`
  );
}

export function building_d_add(data, id) {
  return file.post(`create_building_document?building_id=${id}`, data);
}

export function building_d_remove(id) {
  return instance.delete(`remove_building_document/${id}`);
}

export function building_comment_add(comment, id) {
  return instance.post(
    `create_building_comment?comment=${comment}&building_id=${id}`
  );
}

// main warehouses

export function main_warehouses_get(page, limit, search) {
  if (search) {
    return instance.get(
      `get_main_warehouses?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_main_warehouses?page=${page}&limit=${limit}`);
  }
}

export function main_warehouses_add(data) {
  return instance.post(`create_main_warehouse`, data);
}

export function main_warehouses_put(data) {
  return instance.put(`update_main_warehouse/${data.id}`, data);
}

// money launderers

export function money_launderers_get(page, limit, search) {
  if (search) {
    return instance.get(
      `get_money_launderers?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_money_launderers?page=${page}&limit=${limit}`);
  }
}

export function money_launderers_add(data) {
  return instance.post(`create_money_launderer`, data);
}

export function money_launderers_put(data) {
  return instance.put(`update_money_launderer/${data.id}`, data);
}

// proche sorovlar

export function proche_s_get(
  page,
  limit,
  building_id,
  status,
  user_id,
  search
) {
  if (status && !search) {
    return instance.get(
      `get_product_request?building_id=${building_id}&status=${status}&user_id=${user_id}&page=${page}&limit=${limit}`
    );
  } else if (search && !status) {
    return instance.get(
      `get_product_request?building_id=${building_id}&search=${search}&user_id=${user_id}&page=${page}&limit=${limit}`
    );
  } else if (search && status) {
    return instance.get(
      `get_product_request?building_id=${building_id}&status=${status}&search=${search}&user_id=${user_id}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `get_product_request?building_id=${building_id}&user_id=${user_id}&page=${page}&limit=${limit}`
    );
  }
}

export function proche_s_add(data) {
  return instance.post(`product_request`, data);
}

export function take_supply_proche(data) {
  if (data.type == "proche") {
    return instance.post(`take_supply_by_b_a`, Array(data));
  } else {
    return instance.post(`take_supply_proche`, data);
  }
}

export function proche_s_put(status, t_id, data) {
  return instance.put(
    `confirm_or_reject_product_request?status=${status}&taminotchi_id=${t_id}`,
    data
  );
}

export function accept_supply(data) {
  return instance.put(`supply_acceptance`, data);
}

export function remove_supply_w(id) {
  return instance.put(`supply_return/${id}`);
}

// wallets

export function wallets_get(page, limit, search) {
  if (search) {
    return instance.get(
      `get_wallets?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_wallets?page=${page}&limit=${limit}`);
  }
}

export function wallet_add(data) {
  return instance.post(`create_wallet`, data);
}

export function wallet_put(data) {
  return instance.put(`update_wallet/${data.id}`, data);
}

// workers

export function workers_get(page, limit, search, building_id) {
  if (search) {
    return instance.get(
      `get_workers?building_id=${building_id}&search=${search}&page=${page}&limit=${limit}&search_2=simple`
    );
  } else {
    return instance.get(
      `get_workers?building_id=${building_id}&page=${page}&limit=${limit}`
    );
  }
}

export function worker_works_get(
  page,
  limit,
  building_id,
  type,
  paid_status,
  worker_id
) {
  return instance.get(
    `get_worker_works/${worker_id}?building_id=${building_id}&page=${page}&limit=${limit}${type ? `&type=${type}` : ``
    }${paid_status ? `&paid_status=${paid_status}` : ``}`
  );
}

export function worker_add(data) {
  return instance.post(`create_worker`, data);
}

export function worker_put(data) {
  return instance.put(`update_worker/${data.id}`, data);
}
export function worker_money_add(data) {
  return instance.post(`pay_for_worker`, data);
}

// worker specialities

export function worker_speciality_add(data) {
  return instance.post(`create_worker_specialty`, data);
}

export function worker_speciality_remove(id) {
  return instance.delete(`remove_worker_specialty/${id}`);
}

// markets

export function markets_get(page, limit, search) {
  if (search) {
    return instance.get(
      `get_markets?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(`get_markets?page=${page}&limit=${limit}`);
  }
}

export function shot_request(
  market_id,
  status,
  paid_status,
  building_id,
  type,
  from_time,
  to_time,
  page,
  limit,
  rel_id
) {
  let time_filter = "";
  if (from_time && to_time) {
    time_filter = `&from_time=${from_time}&to_time=${to_time}`;
  }
  let type_filter = "";
  if (type) {
    type_filter = `&type=${type}`;
  } else {
    type_filter = "";
  }
  let status_filter = "";
  if (status) {
    status_filter = `&status=${status}`;
  } else {
    status_filter = "";
  }
  let paid_status_filter = "";
  if (paid_status) {
    paid_status_filter = `&paid_status=${paid_status}`;
  } else {
    paid_status_filter = "";
  }
  return instance.get(
    `get_supplies_by_b_a?market_id=${market_id}${status_filter}${paid_status_filter}&building_id=${building_id}${type_filter}&related_id=${rel_id}${time_filter}&page=${page}&limit=${limit}`
  );
}

export function get_supplies(
  market_id,
  status,
  paid_status,
  building_id,
  type,
  from_time,
  to_time,
  page,
  limit,
  rel_id,
  by_b_a,
  user_id,
  search = ""
) {
  let time_filter = "";
  if (from_time && to_time) {
    time_filter = `&from_time=${from_time}&to_time=${to_time}`;
  }
  let type_filter = "";
  if (type) {
    type_filter = `&type=${type}`;
  } else {
    type_filter = "";
  }
  let status_filter = "";
  if (status) {
    status_filter = `&status=${status}`;
  } else {
    status_filter = "";
  }
  let paid_status_filter = "";
  if (paid_status) {
    paid_status_filter = `&paid_status=${paid_status}`;
  } else {
    paid_status_filter = "";
  }
  let by_b_a_filter = "";
  if (by_b_a) {
    by_b_a_filter = `&by_b_a=${by_b_a}`;
  } else {
    by_b_a_filter = "";
  }
  let user_id_filter = "";
  if (user_id) {
    user_id_filter = `&user_id=${user_id}`;
  } else {
    user_id_filter = "";
  }
  return instance.get(
    `get_supplies?market_id=${market_id}${status_filter}${paid_status_filter}&building_id=${building_id}${type_filter}&related_id=${rel_id}${time_filter}${by_b_a_filter}${user_id_filter}&page=${page}&limit=${limit}${search ? `&search=${search}` : ""
    }`
  );
}

export function pay_for_supply(data) {
  return instance.post(`pay_for_supply`, data);
}

export function pay_for_supply_by_b_a(data) {
  return instance.post(`pay_for_supply_by_b_a`, data);
}

export function remove_supply(id) {
  return instance.delete(`remove_supply/${id}`);
}

export function remove_supply_by_b_a(id) {
  return instance.delete(`remove_supply_by_b_a/${id}`);
}

export function take_supply_b_a(data) {
  if (data.type == "smeta" || data.type == "shot_smeta") {
    data.type = "shot_smeta";
    return instance.post(`take_supply_smeta`, Array(data));
  } else if (data.type == "proche" || data.type == "shot_proche") {
    data.type = "shot_proche";
    return instance.post(`take_supply_proche`, data);
  } else if (data.type == "tarkib" || data.type == "shot_tarkib") {
    data.type = "shot_tarkib";
    return instance.post(`take_supply_product_composition`, data);
  } else {
    console.log("do'nt wokr");
  }
}

export function market_put(data) {
  return instance.put(`update_market/${data.id}`, data);
}

// worker types

export function worker_type_get(page, limit, search) {
  if (search) {
    return instance.get(
      `get_worker_specialty_types?search=${search}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `get_worker_specialty_types?page=${page}&limit=${limit}`
    );
  }
}

export function worker_type_add(data) {
  return instance.post(`create_worker_specialty_type`, data);
}

export function worker_type_remove(id) {
  return instance.delete(`remove_worker_specialty_type/${id}`);
}

// warehouse products

export function warehouse_products(
  type,
  building_id,
  search,
  status,
  page,
  limit
) {
  if (search && status) {
    return instance.get(
      `warehouse_products?type=${type}&building_id=${building_id}&search=${search}&status=${status}&page=${page}&limit=${limit}`
    );
  } else if (search && !status) {
    return instance.get(
      `warehouse_products?type=${type}&building_id=${building_id}&search=${search}&page=${page}&limit=${limit}`
    );
  } else if (!search && status) {
    return instance.get(
      `warehouse_products?type=${type}&building_id=${building_id}&status=${status}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `warehouse_products?type=${type}&building_id=${building_id}&page=${page}&limit=${limit}`
    );
  }
}

export function add_to_invalid_products(data) {
  return instance.post(`add_to_invalid_products`, data);
}

export function warehouse_invalid_products(
  status,
  type,
  search,
  from_time,
  to_time,
  building_id,
  page,
  limit
) {
  if (from_time && to_time && search) {
    return instance.get(
      `invalid_products?status=${status}&type=${type}&search=${search}&from_time=${from_time}&to_time=${to_time}&building_id=${building_id}&page=${page}&limit=${limit}`
    );
  } else if (!from_time && !to_time && search) {
    return instance.get(
      `invalid_products?status=${status}&type=${type}&search=${search}&building_id=${building_id}&page=${page}&limit=${limit}`
    );
  } else if (from_time && to_time && !search) {
    return instance.get(
      `invalid_products?status=${status}&type=${type}&from_time=${from_time}&to_time=${to_time}&building_id=${building_id}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `invalid_products?status=${status}&type=${type}&building_id=${building_id}&page=${page}&limit=${limit}`
    );
  }
}

export function remove_added_invalid_product(id) {
  return instance.delete(`remove_added_invalid_product/${id}`);
}

// product transfer

export function transfered_products(
  status2,
  type,
  from,
  to,
  status,
  search,
  page,
  limit
) {
  if (search && type && status) {
    return instance.get(
      `transfered_products/${status2}?type=${type}&from_=${from}&to_=${to}&status=${status}&search=${search}&page=${page}&limit=${limit}`
    );
  } else if (search && !type && !status) {
    return instance.get(
      `transfered_products/${status2}?from_=${from}&to_=${to}&search=${search}&page=${page}&limit=${limit}`
    );
  } else if (!search && !type && status) {
    return instance.get(
      `transfered_products/${status2}?from_=${from}&to_=${to}&status=${status}&page=${page}&limit=${limit}`
    );
  } else if (!search && type && !status) {
    return instance.get(
      `transfered_products/${status2}?type=${type}&from_=${from}&to_=${to}&page=${page}&limit=${limit}`
    );
  } else if (search && type && !status) {
    return instance.get(
      `transfered_products/${status2}?type=${type}&from_=${from}&to_=${to}&search=${search}&page=${page}&limit=${limit}`
    );
  } else if (search && !type && status) {
    return instance.get(
      `transfered_products/${status2}?from_=${from}&to_=${to}&status=${status}&search=${search}&page=${page}&limit=${limit}`
    );
  } else if (!search && type && status) {
    return instance.get(
      `transfered_products/${status2}?type=${type}&from_=${from}&to_=${to}&status=${status}&page=${page}&limit=${limit}`
    );
  } else {
    return instance.get(
      `transfered_products/${status2}?from_=${from}&to_=${to}&page=${page}&limit=${limit}`
    );
  }
}

export function transfer_product(data) {
  return instance.post(`transfer_product`, data);
}

export function take_back_transfered_product(id) {
  return instance.delete(`take_back_transfered_product/${id}`);
}

export function acceptance_transfered_product(id) {
  return instance.put(`acceptance_transfered_product/${id}`);
}

// other works

export function other_works(
  search,
  building_id,
  status,
  user_id,
  page,
  limit,
  category_id,
  paid_status
) {
  if (search) {
    return instance.get(
      `get_other_works?search=${search}&paid_status=${paid_status}&building_id=${building_id}&status=${status}&user_id=${user_id}&page=${page}&limit=${limit}&category_id=${category_id || 0
      }`
    );
  } else {
    return instance.get(
      `get_other_works?paid_status=${paid_status}&building_id=${building_id}&status=${status || ''}&user_id=${user_id}&page=${page}&limit=${limit}&category_id=${category_id || 0
      }`
    );
  }
}

export function category_other_works(building_id, source_id, page, limit) {
  return instance.get(
    `get_category_other_works?building_id=${building_id}&source_id=${source_id}&page=${page}&limit=${limit}`
  );
}

export function add_other_work(data) {
  return instance.post(`add_other_work`, data);
}

export function add_category_other_work(data) {
  return instance.post(`add_category_other_work`, data);
}

export function remove_other_work(id) {
  return instance.delete(`remove_other_work/${id}`);
}

export function remove_category_other_work(id) {
  return instance.delete(`delete_category_other_work/${id}`);
}

export function update_other_work(data) {
  return instance.put(`update_other_work`, data);
}

export function clone_category_other_work(data) {
  return instance.put(
    `clone_category_other_work/${data.id}?name=${data.name}&comment=${data.comment}`
  );
}

export function update_category_other_work(data) {
  return instance.put(`update_category_other_work`, data);
}

// spent items to work

export function work_confirmation(id, data) {
  return instance.post(`work_confirmation/${id}`, data);
}

export function get_spent_items_to_work(
  work_id,
  worker_id,
  type,
  user_id,
  page,
  limit,
  from_time = "",
  to_time = "",
  building_id = 0
) {
  const date =
    from_time && to_time ? `&from_time=${from_time}&to_time=${to_time}` : "";

  return instance.get(
    `get_spent_items_to_work?work_id=${work_id}&worker_id=${worker_id}${type ? `&type=${type}` : ""
    }&user_id=${user_id}&page=${page}&limit=${limit}&building_id=${building_id}${date}`
  );
}
export function spent_work_add(data) {
  return instance.post(`spent_item_to_work`, data);
}

export function remove_spented_items(id) {
  return instance.delete(`remove_spented_items/${id}`);
}
// share to bank account

export function share_supply_to_b_a(id, status) {
  return instance.put(`share_supply_to_b_a/${id}?status=${status}`);
}

// get market expense

export function get_market_expenses(
  from_,
  to_,
  for_,
  user_id,
  type,
  page,
  limit
) {
  let type_filter = "";
  if (type) {
    type_filter = `&type=${type}`;
  } else {
    type_filter = "";
  }
  return instance.get(
    `get_market_expenses?from_=${from_}&to_=${to_}&for_=${for_}&user_id=${user_id}${type_filter}&page=${page}&limit=${limit}`
  );
}

// remove paid money

export function take_back_money_from_supply_expense(id) {
  return instance.delete(`take_back_money_from_supply_expense/${id}`);
}

// spent products

export function get_spent_products_to_work(
  work_id,
  type,
  user_id,
  item,
  building_id,
  page,
  limit
) {
  let item_filter = "";
  if (item) {
    item_filter = `&item=${item}`;
  } else {
    item_filter = "";
  }
  let type_filter = "";
  if (type) {
    type_filter = `&type=${type}`;
  } else {
    type_filter = "";
  }
  return instance.get(
    `get_spent_products_to_work?work_id=${work_id}${type_filter}&user_id=${user_id}${item_filter}&building_id=${building_id}&page=${page}&limit=${limit}`
  );
}

// expense remove

export function take_back_money_from_expense(id, type) {
  return instance.delete(`take_back_money_from_expense/${id}/${type}`);
}

export function take_back_money_from_supply_by_b_a_expense(id) {
  return instance.delete(`take_back_money_from_supply_by_b_a_expense/${id}`);
}
export function take_back_money_from_work(id) {
  return instance.delete(`take_back_money_from_work/${id}`);
}
export function take_back_money_from_worker(id) {
  return instance.delete(`take_back_money_from_worker/${id}`);
}
// get_total_supply_balance

export function get_total_supply_balance(id) {
  return instance.get(`get_total_supply_balance?building_id=${id}`);
}

// get_work_expenses
export function get_work_expenses(to, type, building_id, page, limit) {
  return instance.get(
    `get_work_expenses?to_=${to}&type=${type}&building_id=${building_id}&page=${page}&limit=${limit}`
  );
}

export function get_worker_expenses(to, building_id, page, limit) {
  return instance.get(
    `get_worker_expenses?to_=${to}&building_id=${building_id}&page=${page}&limit=${limit}`
  );
}

// create proche work
export function create_proche_work(body) {
  return file.post(`create_proche_work/${body.id}`, body);
}

// money requests
export function get_money_requests(id) {
  return instance.get(`get_money_requests/${id}`);
}

export function get_talabnoma(
  from_time,
  to_time,
  user_id,
  confirmed_user_id,
  building_id,
  status,
  page,
  limit
) {
  return instance.get(
    `get_talabnomalar?status=${status}&user_id=${user_id}&confirmed_user_id=${confirmed_user_id}&building_id=${building_id}&page=${page}&limit=${limit}${from_time && to_time ? `&from_time=${from_time}&to_time=${to_time}` : ""
    }`
  );
}

export function update_money_request(data) {
  return instance.put(`update_money_request`, data);
}

export function confirm_talabnoma(id) {
  return instance.put(`confirm_talabnoma/${id}`);
}

export function create_talabnoma(id) {
  return instance.post(`create_talabnoma/${id}`);
}

export function create_money_request(data) {
  return instance.post(`create_money_request`, data);
}
// pay for work
export function pay_for_work(type, data) {
  return instance.post(`pay_for_work/${type}`, data);
}