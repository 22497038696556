<template>
  <Header_item :name="$route.query.name" />

  <ul class="nav nav-tabs nav-justified" id="pills-tab" role="tablist">
    <li class="nav-item mx-1" role="presentation">
      <a
        class="nav-link active"
        id="pills-home-tab"
        data-mdb-toggle="pill"
        data-mdb-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
        @click="get_payments"
        >Толов тарихи
      </a>
    </li>
    <li class="nav-item mx-1" role="presentation">
      <a
        class="nav-link"
        id="pills-profile-tab"
        data-mdb-toggle="pill"
        data-mdb-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
        @click="get_works"
      >
        Ишлар тарихи
      </a>
    </li>
  </ul>

  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade active show"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="table-responsive mt-3 m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Ходим</th>
              <th>Сумма</th>
              <th>Сана</th>
              <th>Изох</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, inc) in payment.data" :key="item">
              <td>{{ inc + 1 }}</td>
              <td>{{ item.user }}</td>
              <td>
                {{ new Intl.NumberFormat().format(item.Expenses.money) }} сўм
              </td>
              <td>
                {{
                  new Date(item.Expenses.time)
                    .toLocaleDateString({
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                    .slice(0, 10)
                }}
              </td>
              <td>{{ item.Expenses.comment }}</td>
              <td data-exclude="true">
                <button
                  class="btn btn-sm btn-danger"
                  @click="Remove(item.Expenses.id)"
                >
                  <i class="fa-solid fa-circle-xmark"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <Data_notfound v-if="payment.data == ''" />
      </div>
      <nav
        aria-label="Page navigation example"
        class="mt-1"
        v-if="payment.data != ''"
      >
        <ul class="pagination pagination-sm justify-content-end">
          <li class="page-item">
            <select
              class="form-control form-control-sm form-select mx-1"
              v-model="payment.limit"
              @change="get_payments()"
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" @click="get_payments()">
              <i class="fa-solid fa-rotate"></i>
            </button>
          </li>
          <li class="page-item">
            <button
              class="page-link"
              @click="
                payment.page--;
                get_payments();
              "
              :disabled="payment.page == 0"
            >
              <i class="fa-solid fa-angle-left text-dark"></i>
            </button>
          </li>
          <li class="page-item">
            <select
              class="form-control form-control-sm page_select"
              v-model="payment.page"
              @change="get_payments()"
            >
              <option
                :value="item - 1"
                v-for="item in payment.pages"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
          </li>
          <li class="page-item">
            <button
              class="page-link"
              @click="
                payment.page++;
                get_payments();
              "
              :disabled="payment.page == payment.pages - 1"
            >
              <i class="fa-solid fa-angle-right text-dark"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div class="table-responsive mt-3 m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Иш</th>
              <th>Сумма</th>
              <th>Ажратилган нарх</th>
              <th>Микдори</th>
              <th>Сана</th>
              <th>Изох</th>
              <th>Прорап изох</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, inc) in work.data" :key="item">
              <td>{{ inc + 1 }}</td>
              <td>{{ item.work.name }}</td>
              <td>
                {{ new Intl.NumberFormat().format(item.work_price) }}
                сўм
              </td>
              <td>
                {{ new Intl.NumberFormat().format(item.work.attached_price) }}
                сўм
              </td>
              <td>
                {{ new Intl.NumberFormat().format(item.work.quantity) }}
                {{ item.work.olchov_birligi }}
              </td>
              <td>
                {{
                  new Date(item.work.time)
                    .toLocaleDateString({
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                    .slice(0, 10)
                }}
              </td>
              <td>{{ item.work.comment }}</td>
              <td>{{ item.work.comment_prorap }}</td>
              <td>
                <button
                  class="btn btn-primary btn-sm"
                  data-mdb-toggle="modal"
                  data-mdb-target="#payment_history"
                  id="main_pay"
                  @click="
                    payment_history.work_id = item.work.id;
                    get_payment_history();
                  "
                >
                  <i class="fa-solid fa-history"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <Data_notfound v-if="work.data == ''" />
      </div>
      <nav
        aria-label="Page navigation example"
        class="mt-1"
        v-if="work.data != ''"
      >
        <ul class="pagination pagination-sm justify-content-end">
          <li class="page-item">
            <select
              class="form-control form-control-sm form-select mx-1"
              v-model="work.limit"
              @change="get_works()"
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" @click="get_works()">
              <i class="fa-solid fa-rotate"></i>
            </button>
          </li>
          <li class="page-item">
            <button
              class="page-link"
              @click="
                work.page--;
                get_works();
              "
              :disabled="work.page == 0"
            >
              <i class="fa-solid fa-angle-left text-dark"></i>
            </button>
          </li>
          <li class="page-item">
            <select
              class="form-control form-control-sm page_select"
              v-model="work.page"
              @change="get_works()"
            >
              <option :value="item - 1" v-for="item in work.pages" :key="item">
                {{ item }}
              </option>
            </select>
          </li>
          <li class="page-item">
            <button
              class="page-link"
              @click="
                work.page++;
                get_works();
              "
              :disabled="work.page == work.pages - 1"
            >
              <i class="fa-solid fa-angle-right text-dark"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="modal fade" id="payment_history">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Иш учун толов тарихи</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-3 m-1">
            <table class="table table-sm table-hover">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Сумма</th>
                  <th>Миқдори</th>
                  <th>Чиқим</th>
                  <th>Қолдиқ</th>
                  <th>Ходим</th>
                  <th>Сана</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, inc) in payment_history.data" :key="item">
                  <td>{{ inc + 1 }}</td>
                  <td>
                    {{
                      new Intl.NumberFormat().format(
                        item.Spent_items_to_work.price
                      )
                    }}
                    сўм
                  </td>
                  <td>{{ item.Spent_items_to_work.quantity }}</td>
                  <td>
                    {{ new Intl.NumberFormat().format(item.sum_expense) }}
                  </td>
                  <td>
                    {{
                      new Intl.NumberFormat().format(
                        item.Spent_items_to_work.price *
                          item.Spent_items_to_work.quantity
                      )
                    }}
                    /
                    {{
                      new Intl.NumberFormat().format(
                        item.Spent_items_to_work.price *
                          item.Spent_items_to_work.quantity -
                          item.sum_expense
                      )
                    }}
                  </td>
                  <td>{{ item.user }}</td>
                  <td>
                    {{
                      new Date(item.Spent_items_to_work.time)
                        .toLocaleDateString({
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                        .slice(0, 10)
                    }}
                  </td>
                  <td v-if="role === 'admin' || role === 'prorap'">
                    <button
                      class="btn btn-primary btn-sm m-1"
                      data-mdb-target="#payment_history2"
                      data-mdb-toggle="modal"
                      @click="
                        (payment_history2.work_id =
                          item?.Spent_items_to_work.id),
                          get_payment_history_id()
                      "
                    >
                      <i class="fa-solid fa-circle-info"></i>
                    </button>
                    <button
                      class="btn btn-success btn-sm m-1"
                      data-mdb-target="#money_pay"
                      data-mdb-toggle="modal"
                      @click="
                        money_data.to_ = item.Spent_items_to_work.id;
                        money_data.for_ = item.Spent_items_to_work.worker_id;
                        money_data.sum =
                          item.Spent_items_to_work.price *
                            item.Spent_items_to_work.quantity -
                          item.sum_expense;
                        money_data.balance = item.worker_balance;
                      "
                    >
                      <i class="fas fa-money-check"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm m-1"
                      @click="removeWorkExpense(item.Spent_items_to_work.id)"
                    >
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <Data_notfound v-if="payment_history.data == ''" />
          </div>
          <nav
            aria-label="Page navigation example"
            class="mt-1"
            v-if="payment_history.data != ''"
          >
            <ul class="pagination pagination-sm justify-content-end">
              <li class="page-item">
                <select
                  class="form-control form-control-sm form-select mx-1"
                  v-model="payment_history.limit"
                  @change="get_payment_history()"
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </li>
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  @click="get_payment_history()"
                >
                  <i class="fa-solid fa-rotate"></i>
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="
                    payment_history.page--;
                    get_payment_history();
                  "
                  :disabled="payment_history.page == 0"
                >
                  <i class="fa-solid fa-angle-left text-dark"></i>
                </button>
              </li>
              <li class="page-item">
                <select
                  class="form-control form-control-sm page_select"
                  v-model="payment_history.page"
                  @change="get_payment_history()"
                >
                  <option
                    :value="item - 1"
                    v-for="item in payment_history.pages"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="
                    payment_history.page++;
                    get_payment_history();
                  "
                  :disabled="payment_history.page == payment_history.pages - 1"
                >
                  <i class="fa-solid fa-angle-right text-dark"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="payment_history2"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Тўловлар тарихи</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-end">
            <select
              class="form-control form-control-xl"
              v-model="payment_history2.type"
              @change="get_payment_history_id()"
            >
              <option value="other_work_expense">Прорап</option>
              <option value="other_work_from_worker_expense">Уста</option>
            </select>
          </div>
          <div class="table-responsive mt-3 m-1">
            <table class="table table-sm table-hover">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Сумма</th>
                  <th>Ходим</th>
                  <th>Сана</th>
                  <th>Ҳақида</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, inc) in payment_history2.data" :key="item">
                  <td>{{ inc + 1 }}</td>
                  <td>
                    {{ new Intl.NumberFormat().format(item.Expenses.money) }}
                    сўм
                  </td>
                  <td>{{ item.user }}</td>
                  <td>
                    {{ item.Expenses.time.replace("T", " ") }}
                  </td>
                  <td>
                    {{ item.Expenses.comment }}
                  </td>
                  <td>
                    <button
                      class="btn btn-danger btn-sm m-1"
                      @click="removePay(item.Expenses.id)"
                    >
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <Data_notfound v-if="payment_history2.data == ''" />
          </div>
          <nav
            aria-label="Page navigation example"
            class="mt-1"
            v-if="payment_history2.data != ''"
          >
            <ul class="pagination pagination-sm justify-content-end">
              <li class="page-item">
                <select
                  class="form-control form-control-sm form-select mx-1"
                  v-model="payment_history2.limit"
                  @change="get_payment_history_id()"
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </li>
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  @click="get_payment_history_id()"
                >
                  <i class="fa-solid fa-rotate"></i>
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="
                    payment_history2.page--;
                    get_payment_history_id();
                  "
                  :disabled="payment_history2.page == 0"
                >
                  <i class="fa-solid fa-angle-left text-dark"></i>
                </button>
              </li>
              <li class="page-item">
                <select
                  class="form-control form-control-sm page_select"
                  v-model="payment_history2.page"
                  @change="get_payment_history_id()"
                >
                  <option
                    :value="item - 1"
                    v-for="item in payment_history2.pages"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="
                    payment_history2.page++;
                    get_payment_history_id();
                  "
                  :disabled="
                    payment_history2.page == payment_history2.pages - 1
                  "
                >
                  <i class="fa-solid fa-angle-right text-dark"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary btn-sm"
            data-mdb-target="#payment_history"
            data-mdb-toggle="modal"
          >
            Орқага
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- pay money -->
  <div class="modal fade" id="money_pay">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Пул тўлаш</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-target="#payment_history"
            data-mdb-toggle="modal"
          ></button>
        </div>
        <div class="row px-2">
          <h5 class="text-center">
            Jami: {{ new Intl.NumberFormat().format(money_data.sum) }} сўм
          </h5>
        </div>

        <form @submit.prevent="PayForWork()">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <select
                  class="form-control form-control-sm"
                  v-model="money_data.type"
                >
                  <option value="other_work">Прораб</option>
                  <option value="other_work_from_worker">
                    Уста {{ money_data.balance }} сўм
                  </option>
                </select>
              </div>
              <div class="col-10 mx-auto">
                <label for="sum"> Сумма: </label>
                <input
                  type="number"
                  id="sum"
                  class="form-control form-control-sm"
                  v-model="money_data.money"
                  required
                  placeholder="Суммани киритинг..."
                />
              </div>
              <div class="col-10 mx-auto">
                <label for="izoh"> Изоҳ: </label>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    id="izoh"
                    class="form-control form-control-sm"
                    required
                    v-model="money_data.comment"
                    placeholder="Изоҳ киритинг..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="worker_disabled"
              :disabled="money_data.money === ''"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-target="#payment_history"
              data-mdb-toggle="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_spent_items_to_work,
  get_worker_expenses,
  worker_works_get,
  get_work_expenses,
  take_back_money_from_worker,
  pay_for_work,
  remove_spented_items,
  take_back_money_from_work,
} from "@/utils/request/request";
import { catchError, success } from "@/utils/request/handle_api_err";
import Header_item from "@/components/header/header_item.vue";
import Data_notfound from "@/utils/notfound/data_notfound.vue";

export default {
  name: "worker_history",
  data() {
    return {
      building_id: this.$route.query.building_id,
      worker_id: this.$route.query.id,
      role: localStorage.getItem("role"),
      payment: {
        limit: 25,
        page: 0,
        pages: null,
        data: [],
      },
      payment_history: {
        limit: 25,
        page: 0,
        pages: null,
        data: [],
        work_id: null,
      },
      payment_history2: {
        limit: 25,
        page: 0,
        type: "other_work_expense",
        pages: null,
        data: [],
        work_id: null,
      },
      work: {
        limit: 25,
        page: 0,
        pages: null,
        type: "other_work",
        paid_status: "",
        data: [],
      },
      money_data: {
        money: "",
        from_: 0,
        to_: 0,
        for_: 0,
        comment: "",
        type: "other_work",
        sum: 0,
        balance: 0,
      },
      type: true,
    };
  },
  methods: {
    get_payments() {
      this.$store.dispatch("setLoading", { isLoading: true });

      get_worker_expenses(
        this.worker_id,
        this.building_id,
        this.payment.page,
        this.payment.limit
      )
        .then((res) => {
          this.payment.data = res.data.data;
          this.payment.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    get_works() {
      this.$store.dispatch("setLoading", { isLoading: true });

      worker_works_get(
        this.work.page,
        this.work.limit,
        this.building_id,
        this.work.type,
        this.work.paid_status,
        this.worker_id
      )
        .then((res) => {
          this.work.data = res.data.data;
          this.work.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    get_payment_history() {
      this.$store.dispatch("setLoading", { isLoading: true });

      get_spent_items_to_work(
        this.payment_history.work_id,
        this.worker_id,
        "other_work",
        0,
        this.payment_history.page,
        this.payment_history.limit,
        "",
        "",
        this.building_id
      )
        .then((res) => {
          this.payment_history.data = res.data.data;
          this.payment_history.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    get_payment_history_id() {
      this.$store.dispatch("setLoading", { isLoading: true });

      get_work_expenses(
        this.payment_history2.work_id,
        this.payment_history2.type,
        0,
        this.payment_history2.page,
        this.payment_history2.limit
      )
        .then((res) => {
          this.payment_history2.data = res.data.data;
          this.payment_history2.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    Remove(id) {
      take_back_money_from_worker(id)
        .then((res) => {
          success(res);
          this.get_payments();
        })
        .catch((err) => {
          catchError(err);
        });
    },
    PayForWork() {
      this.$store.dispatch("setLoading", { isLoading: true });
      let arr = [];
      this.money_data.from_ =
        this.money_data.type == "other_work" ? 0 : this.money_data.for_;
      arr.push(this.money_data);
      pay_for_work(this.money_data.type, arr)
        .then((res) => {
          success(res);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("main_pay").click();
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    removeWorkExpense(id) {
      remove_spented_items(id).then((res) => {
        success(res);
        this.get_payment_history();
      });
    },
    removePay(id) {
      take_back_money_from_work(id).then((res) => {
        success(res);
        this.get_payment_history_id();
      });
    },
  },
  created() {
    this.get_payments();
  },
  components: { Header_item, Data_notfound },
};
</script>
