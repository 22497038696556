<template>
  <Header_item />

  <form @submit.prevent="time" class="mb-3">
    <div class="row">
      <div class="col">
        <input
          type="date"
          class="form-control form-control-sm"
          v-model="h_from_time"
          required
        />
      </div>
      <div class="col">
        <input
          type="date"
          class="form-control form-control-sm"
          v-model="h_to_time"
          required
        />
      </div>
      <div class="col">
        <div class="btn-group w-100">
          <button
            class="btn btn-outline-primary btn-sm w-80"
            :disabled="h_from_time == '' || h_to_time == ''"
            @click="building_d_gets()"
          >
            <i class="fa-solid fa-search"></i>
          </button>
          <button
            class="btn btn-outline-danger btn-sm w-20"
            @click="(h_to_time = ''), (h_from_time = '')"
            v-if="h_from_time && h_to_time"
          >
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="text-end mb-2 zoom">
    <button
      class="btn btn-sm btn-primary"
      data-mdb-toggle="modal"
      data-mdb-target="#video_document"
    >
      <i class="fas fa-circle-plus fs-4"></i>
    </button>
  </div>
  <div>
    <div class="row row_building">
      <div
        class="col-md-4 col-sm-6 col-lg-4 col-xxl-4 my-1"
        v-for="(item, index) in building"
        :key="item?.Building_documents.id"
      >
        <div class="card h-100">
          <div class="card-body text-center">
            <span class="float-start badge bg-primary mb-2">{{
              index + 1
            }}</span>
            <video
              class="w-100 h-50 rounded-3 mb-3 bg-black"
              controls
              :src="baseUrl + 'uploaded_files/' + item?.Building_documents.file"
              style="max-height: 300px !important"
            ></video>
            <hr class="mt-2 mb-2 dark" />

            <h6>
              <b>Санаси: </b>
              {{ item?.Building_documents.time.replace("T", " ") }}
            </h6>

            <h6>
              <b>{{ item?.Building_documents.comment }}</b>
            </h6>
            <hr class="mt-2 mb-2 dark" />
            <div class="coll mx-1">
              <button
                v-if="false"
                class="btn btn-sm btn-danger btn-active-bg-green"
                data-mdb-toggle="modal"
                data-mdb-target="#exampleModal"
                @click="item_id = item?.Building_documents.id"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade modal_add"
    id="video_document"
    tabindex="-1"
    aria-labelledby="foremans_warehousemans"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button
            type="button"
            class="btn-close float-end"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col">
            <form action="" @submit.prevent="building_video_create()">
              <div class="col">
                <div class="mb-3">
                  <h6 class="text-md-start">Видео</h6>
                  <input
                    type="file"
                    accept="video/*"
                    class="border border-dark rounded form-control"
                    required
                    @change="create_building_add.file = $event.target.files[0]"
                  />
                </div>
                <div class="w-100 mb-3">
                  <h6 class="text-md-start">Хақида</h6>
                  <textarea
                    class="w-100 border border-dark rounded"
                    required
                    style="height: 150px"
                    v-model="create_building_add.comment"
                  ></textarea>
                </div>
                <div class="w-100 text-end">
                  <button class="btn btn-success" type="submit">
                    <i class="fa-solid fa-check"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            Маълумот ўчириш !
          </h1>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="close"
          ></button>
        </div>
        <div class="modal-body">Видеони ўчирмоқчимисиз ?</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            id="close_video_create"
            data-mdb-dismiss="modal"
          >
            Йўқ
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="building_d_remove()"
          >
            Ҳа
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header_item from "@/components/header/header_item.vue";
import {
  building_d_get,
  building_d_add,
  url,
  building_d_remove,
} from "@/utils/request/request";
import { catchError, success } from "@/utils/request/handle_api_err";
export default {
  name: "Video_Page",
  data() {
    return {
      h_from_time: "",
      h_to_time: "",
      id: this.$route.query.id,
      type: "",
      building: null,
      baseUrl: url,
      item_id: Number,
      create_building_add: {
        file: null,
        comment: "",
        type: "daily_file",
      },
    };
  },
  components: { Header_item },
  mounted() {
    // navigator.mediaDevices
    //   .getUserMedia({ video: true })
    //   .then((stream) => {
    //     this.$refs.video.srcObject = stream;
    //   })
    //   .catch((error) => {
    //     console.error("Error accessing camera:", error);
    //   });
  },
  methods: {
    building_d_gets() {
      this.$store.dispatch("setLoading", { isLoading: true });
      building_d_get(this.id, this.type, this.h_from_time, this.h_to_time)
        .then((res) => {
          this.documents = res.data;
          this.building = res.data;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    building_video_create() {
      this.$store.dispatch("setLoading", { isLoading: true });
      building_d_add(this.create_building_add, this.id)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          success(res);
          document.getElementById("close_video_create").click();
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    building_d_remove() {
      building_d_remove(this.item_id)
        .then((res) => {
          document.getElementById("close").click();
          success(res);
        })
        .catch((err) => {
          catchError(err);
        });
    },
  },
  beforeUnmount() {
    // if (this.$refs.video.srcObject) {
    //   this.$refs.video.srcObject.getTracks().forEach((track) => track.stop());
    // }
  },
  created() {
    this.building_d_gets();
  },
};
</script>
<style lang="scss" scoped>
table {
  min-width: 1000px;
  width: 100%;
}

.table-responsive {
  max-height: 66vh;
}

div.row_building {
  max-height: 68vh;
  overflow: auto;
}

@media (max-width: 500px) {
  .table-responsive {
    max-height: 85vh;
  }
}

.modal-content {
  border: none;
}

.modal-body.modal_add {
  height: 50vh;
}

.descinline {
  display: inline;
}

.descempty {
  display: none;
}

hr {
  border: none;
  opacity: 1;
  border-radius: 50px;
}

hr.success {
  background: green;
  animation: success 0.8s infinite alternate linear;
}

hr.dark {
  background: black;
  animation: darker 0.8s infinite alternate linear;
}

hr.danger {
  background: red;
  animation: danger 0.8s infinite alternate linear;
}

hr.warning {
  background: #ffc107;
  animation: warning 0.8s infinite alternate linear;
}

.card.success {
  border: 1px solid green;
  animation: success 0.8s infinite alternate linear;
}

.card.dark {
  border: 1px solid black;
  animation: darker 0.8s infinite alternate linear;
}

.card.danger {
  border: 1px solid red;
  animation: danger 0.8s infinite alternate linear;
}

.card.warning {
  border: 1px solid #ffc107;
  animation: warning 0.8s infinite alternate linear;
}

@keyframes success {
  0% {
    box-shadow: 0 0 0 1.8px rgb(0, 128, 0, 0.4);
  }
}

@keyframes danger {
  0% {
    box-shadow: 0 0 0 1.8px rgb(255, 0, 0, 0.4);
  }
}

@keyframes warning {
  0% {
    box-shadow: 0 0 0 1.8px rgb(255, 193, 7, 0.4);
  }
}

@keyframes darker {
  0% {
    box-shadow: 0 0 0 1.8px rgb(0, 0, 0, 0.4);
  }
}

.zoom .coll:hover {
  transform: scale(1.1) translateY(-2px);
}

.zoom .coll {
  margin: 5px 0px;
  transition: all 0.1s linear;
}

.d-flex.justify-content-center.zoom {
  zoom: 92%;
  flex-wrap: wrap;
}
</style>
