<template>
  <Header_item :name="this.$route.query.name" />
  <!-- <ul
    class="nav nav-tabs nav-justified nav-fill mb-3"
    id="pills-tab"
    role="tablist"
  >
    <li class="nav-item mx-1" role="presentation">
      <a
        class="nav-link active"
        id="pills-home-tab"
        data-mdb-toggle="pill"
        data-mdb-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
        @click="
          (confirm_body.type = 'smeta_work'), (recheck_body.type = 'smeta_work')
        "
      >
        сметадаги ишлар
      </a>
    </li>
    <li class="nav-item mx-1" role="presentation">
      <a
        class="nav-link"
        id="pills-profile-tab"
        data-mdb-toggle="pill"
        data-mdb-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
        @click="
          other_works.length
            ? ''
            : get_other_work(
                other_search,
                other_building_id,
                other_status,
                other_user_id,
                other_page,
                other_limit
              );
          (confirm_body.type = 'other_work'),
            (recheck_body.type = 'other_work');
        "
      >
        сметадан ташқари ишлар
      </a>
    </li>
  </ul> -->
  <!-- <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="row">
        <div class="col mt-1 d-flex align-items-center">
          <span>Лимит</span>
          <select
            class="form-control form-control-sm mx-2 form-select smeta_sort"
            v-model="smeta_limit"
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <select
            class="form-control form-control-sm mx-2 form-select smeta_sort"
            v-model="smeta_status"
          >
            <option value=" ">бажарилаётганлар</option>
            <option value="wait">кутиш</option>
            <option value="re-check">қайта-текширув</option>
            <option value="confirmed">тасдиқланган</option>
          </select>
        </div>
        <div class="col mt-2 d-flex align-items-center justify-content-end">
          <span>Қидириш: </span>
          <div class="input-group input-group-sm mx-2">
            <input
              type="search"
              v-model="smeta_search"
              class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
      <div class="table-responsive mt-3" style="overflow: auto">
        <table class="table table-sm table-hover smeta work" id="smeta_work">
          <thead>
            <tr>
              <th style="width: 5%"></th>
              <th style="width: 5%">ШНК</th>
              <th style="width: 15%">Номи</th>
              <th style="width: 5%">Ўлчов бирлиги</th>
              <th style="width: 10%">Ҳажм</th>
              <th style="width: 10%">Ишчи учун ажратилган вақт</th>
              <th style="width: 10%">Машина учун ажратилган вақт</th>
              <th style="width: 10%">Ажратилган сумма</th>
              <th style="width: 5%">Муддат</th>
              <th style="width: 10%">Изох</th>
              <th style="width: 10%">Сана</th>
              <th v-if="role != 'admin'"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in smeta_works" :key="item">
              <td>
                <span
                  class="badge rounded-pill"
                  style="padding: 7.8px 7.8px"
                  :class="
                    date < item.Smeta.end_date &&
                    date_sort(item.Smeta.end_date) > 5
                      ? 'bg-success text-success'
                      : date < item.Smeta.end_date &&
                        date_sort(item.Smeta.end_date) <= 5 &&
                        date_sort(item.Smeta.end_date) >= 3
                      ? 'bg-warning text-warning'
                      : 'bg-danger text-danger'
                  "
                  ><i class="fa-solid fa-circle"></i>
                </span>
              </td>
              <td>{{ item.Smeta.shnk }}</td>
              <td>{{ item.Smeta.name }}</td>
              <td>{{ item.Smeta.olchov_birligi }}</td>
              <td>
                <span class="d-flex">
                  <Format_numbers :number="item.Smeta.quantity" class="mx-1" />/
                  {{ item.completed_quantity ? item.completed_quantity : 0 }}
                </span>
              </td>
              <td>
                <Format_numbers :number="item.Smeta.allotted_time_for_worker" />
              </td>
              <td>
                <Format_numbers
                  :number="item.Smeta.allotted_time_for_machine"
                />
              </td>
              <td>
                <span v-if="item.Smeta.allotted_price" class="d-flex">
                  <Format_numbers
                    :number="item.Smeta.allotted_price"
                    class="mx-1"
                  />
                  сўм
                </span>
                <span v-else>бириктирилмаган</span>
              </td>
              <td>{{ item.Smeta.muddat }} кун</td>
              <td>
                {{ item.Smeta.comment }}
                <hr class="m-0" />
                {{ item.Smeta.comment_prorap }}
              </td>
              <td>
                {{
                  new Date(item.Smeta.start_date)
                    .toLocaleDateString({
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                    .slice(0, 10) +
                  " | " +
                  new Date(item.Smeta.end_date)
                    .toLocaleDateString({
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                    .slice(0, 10)
                }}
              </td>
              <td data-exclude="true" v-if="role != 'admin'">
                <button
                  class="btn btn-sm btn-success mx-1"
                  v-if="
                    item.Smeta.status == 'wait' ||
                    item.Smeta.status == 're-check'
                  "
                  @click="work_id = item.Smeta.id"
                  data-mdb-toggle="modal"
                  data-mdb-target="#confirm_modal"
                >
                  <i class="fa-solid fa-circle-check"></i>
                </button>
                <button
                  class="btn btn-sm btn-primary mx-1"
                  v-if="item.Smeta.status == 'wait'"
                  @click="work_id = item.Smeta.id"
                  data-mdb-toggle="modal"
                  data-mdb-target="#recheck_modal"
                >
                  <i class="fa-solid fa-rotate"></i>
                </button>
                <button
                  class="btn btn-sm btn-danger mx-1"
                  v-if="
                    item.Smeta.status == 'confirmed' ||
                    item.Smeta.status == 're-check'
                  "
                  @click="remove(item.Smeta.id, 'smeta_work_remove_confirmed')"
                >
                  <i class="fa-solid fa-circle-xmark"></i>
                </button>
                <router-link
                  :to="{
                    name: 'work_info',
                    query: {
                      id: item.Smeta.id,
                      type: 'smeta_work',
                      name: item.Smeta.name,
                      olchov: item.Smeta.olchov_birligi,
                    },
                  }"
                  class="btn btn-sm btn-primary mx-1"
                >
                  <i class="fa-solid fa-circle-info"></i>
                </router-link>
                <a
                  v-if="item.Smeta.file"
                  target="_blank"
                  :href="img_path + item.Smeta.file"
                  class="btn btn-sm btn-primary mx-1"
                >
                  <i class="fa-regular fa-image"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <Data_notfound v-if="smeta_works == ''" />
      </div>

      <nav
        aria-label="Page navigation example"
        class="mt-2"
        v-if="smeta_works != ''"
      >
        <ul class="pagination pagination-sm justify-content-end">
          <li>
            <Select_data :id="'smeta_work'" />
          </li>
          <li class="page-item">
            <button
              type="button"
              class="page-link"
              @click="
                get_smeta_work(
                  smeta_page,
                  smeta_limit,
                  smeta_search,
                  smeta_type,
                  smeta_status,
                  smeta_building_id,
                  smeta_order_by
                )
              "
            >
              <i class="fa-solid fa-rotate"></i>
            </button>
          </li>
          <li class="page-item">
            <button
              class="page-link"
              @click="smeta_page--"
              :disabled="smeta_page == 0"
            >
              <i class="fa-solid fa-angle-left text-dark"></i>
            </button>
          </li>
          <li class="page-item">
            <select
              class="form-control form-control-sm page_select"
              v-model="smeta_page"
            >
              <option :value="item - 1" v-for="item in smeta_pages" :key="item">
                {{ item }}
              </option>
            </select>
          </li>
          <li class="page-item">
            <button
              class="page-link"
              @click="smeta_page++"
              :disabled="smeta_page == smeta_pages - 1"
            >
              <i class="fa-solid fa-angle-right text-dark"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <div
      class="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    > -->
  <div v-if="role != 'prorap'">
    <diagramma ref="diagramma" />
  </div>

  <div class="row">
    <div class="col mt-1 d-flex align-items-center">
      <span>Лимит</span>
      <select
        class="form-control form-control-sm mx-2 form-select smeta_sort"
        v-model="other_limit"
        @change="
          get_other_work(
            other_search,
            other_building_id,
            other_status,
            other_user_id,
            other_page,
            other_limit
          )
        "
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <select
        class="form-control form-control-sm mx-2 form-select smeta_sort"
        v-model="other_status"
      >
        <option value="">барчаси</option>
        <option value=" ">бажарилаётганлар</option>
        <option value="wait">кутиш</option>
        <option value="re-check">қайта-текширув</option>
        <option value="confirmed">тасдиқланган</option>
      </select>
      <select
        class="form-control form-control-sm mx-2 form-select smeta_sort"
        v-model="paid_status"
        @change="
          get_other_work(
            other_search,
            other_building_id,
            other_status,
            other_user_id,
            other_page,
            other_limit
          )
        "
      >
        <option value="true">To'langan</option>
        <option value="false">To'lanmagan</option>
      </select>
    </div>
    <div class="col mt-2 d-flex align-items-center justify-content-end">
      <span>Қидириш: </span>
      <div class="input-group input-group-sm mx-2">
        <input
          type="search"
          v-model="other_search"
          class="form-control form-control-sm"
        />
        <button
          v-if="role == 'pto'"
          class="btn btn-outline-secondary"
          type="button"
          data-mdb-toggle="modal"
          data-mdb-target="#add_work"
        >
          <i class="fa-solid fa-circle-plus"></i>
        </button>
        <router-link
          v-if="role !== 'prorap'"
          :to="{
            name: 'work_info',
            query: {
              building_id: $route.query.id,
            },
          }"
          class="btn btn-sm btn-primary"
        >
          <i class="fa-solid fa-history"></i>
        </router-link>
      </div>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <table class="table table-sm table-hover smeta other_work" id="other_work">
      <thead>
        <tr>
          <th>Номи</th>
          <th>Ўлчов бирлиги</th>
          <th>Ҳажм</th>
          <!-- <th >Ажратилган сумма</th> -->
          <th>Бошлаш сана</th>
          <th>Қолдиқ сана</th>
          <th>Тугаш сана</th>
          <th>Қайта текширув сана</th>
          <th>Кошган ходим</th>
          <th>Тасдиқлаган ходим</th>
          <th>Изох</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in other_works"
          :key="item"
          :class="
            count_days(new Date(), item.Other_works.end_date) > 5
              ? 'border border-4 border-success'
              : count_days(new Date(), item.Other_works.end_date) <= 5 &&
                count_days(new Date(), item.Other_works.end_date) >= 3
              ? 'border border-4 border-warning'
              : 'border border-4 border-danger'
          "
        >
          <td>{{ item.Other_works.name }}</td>
          <td>{{ item.Other_works.olchov_birligi }}</td>
          <td>
            <span class="d-flex">
              <Format_numbers
                :number="item.Other_works.quantity"
                class="mx-1"
              />
              /
              {{ item.completed_quantity ? item.completed_quantity : 0 }}
            </span>
          </td>
          <!-- <td>
            <span v-if="item.Other_works.attached_price" class="d-flex">
              <Format_numbers
                :number="item.Other_works.attached_price"
                class="mx-1"
              />
              сўм
            </span>
            <span v-else> бириктирилмаган </span>
          </td> -->
          <td>
            {{
              new Date(item.Other_works.time)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>{{ count_days(new Date(), item.Other_works.end_date) }} кун</td>
          <td>
            {{
              new Date(item.Other_works.end_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>
            {{
              item.Other_works.time_to_recheck == "0000-00-00"
                ? "бириктирилмаган"
                : new Date(item.Other_works.time_to_recheck)
                    .toLocaleDateString({
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })
                    .slice(0, 10)
            }}
          </td>
          <td>{{ item.prorap }}</td>
          <td>{{ item.pto }}</td>
          <td>
            {{ item.Other_works.comment_prorap }}
            <hr class="m-0" />
            {{ item.Other_works.comment }}
          </td>
          <td data-exclude="true" v-if="role !== 'admin'">
            <button
              class="btn btn-sm btn-success mx-1"
              v-if="
                (item.Other_works.status == 'wait' ||
                  item.Other_works.status == 're-check') &&
                role !== 'prorap'
              "
              @click="work_id = item.Other_works.id"
              data-mdb-toggle="modal"
              data-mdb-target="#confirm_modal"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              class="btn btn-sm btn-primary mx-1"
              v-if="item.Other_works.status == 'wait' && role !== 'prorap'"
              @click="work_id = item.Other_works.id"
              data-mdb-toggle="modal"
              data-mdb-target="#recheck_modal"
            >
              <i class="fa-solid fa-rotate"></i>
            </button>
            <button
              class="btn btn-sm btn-warning mx-1"
              v-if="role == 'pto'"
              @click="
                editWork.id = item.Other_works.id;
                editWork.name = item.Other_works.name;
                editWork.olchov_birligi = item.Other_works.olchov_birligi;
                editWork.quantity = item.Other_works.completed_quantity;
                editWork.comment_prorap = '';
                editWork.time = item.Other_works.time;
                editWork.end_date = item.Other_works.end_date;
              "
              data-mdb-target="#edit_work"
              data-mdb-toggle="modal"
            >
              <i class="fa fa-edit"></i>
            </button>

            <button
              class="btn btn-sm btn-danger mx-1"
              v-if="
                item.Other_works.status == 'confirmed' ||
                item.Other_works.status == 're-check' ||
                (item.Other_works.status == ' ' &&
                  !['pto', 'prorap'].includes(role))
              "
              @click="
                remove(item.Other_works.id, 'other_work_remove_confirmed')
              "
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
            <button
              class="btn btn-sm btn-danger mx-1"
              v-if="role == 'pto'"
              @click="remove_work(item.Other_works.id)"
            >
              O'chirish
            </button>
            <router-link
              :to="{
                name: 'work_info',
                query: {
                  id: item.Other_works.id,
                  type: 'other_work',
                  name: item.Other_works.name,
                  olchov: item.Other_works.olchov_birligi,
                },
              }"
              class="btn btn-sm btn-primary mx-1"
            >
              <i class="fa-solid fa-circle-info"></i>
            </router-link>
            <a
              v-if="item.Other_works.file"
              target="_blank"
              :href="img_path + item.Other_works.file"
              class="btn btn-sm btn-primary mx-1"
            >
              <i class="fa-regular fa-image"></i>
            </a>
            <button
              v-if="role == 'prorap'"
              class="btn btn-success btn-sm mx-1"
              data-mdb-toggle="modal"
              data-mdb-target="#addModal"
              @click="work_add_data.work_id = item?.Other_works?.id"
            >
              <i class="fa-solid fa-check"></i>
            </button>
            <button
              v-if="role == 'prorap'"
              data-mdb-toggle="modal"
              data-mdb-target="#product"
              class="btn btn-success btn-sm mx-1"
              @click="product_consumption.work_id = item?.Other_works?.id"
            >
              <i class="fa-solid fa-dolly"></i>
            </button>
          </td>
          <td v-else-if="role == 'admin'">
            <router-link
              :to="{
                name: 'work_info',
                query: {
                  id: item.Other_works.id,
                  type: 'other_work',
                  name: item.Other_works.name,
                  olchov: item.Other_works.olchov_birligi,
                },
              }"
              class="btn btn-sm btn-primary mx-1"
            >
              <i class="fa-solid fa-circle-info"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <Data_notfound v-if="other_works == ''" />
  </div>
  <nav
    aria-label="Page navigation example"
    class="mt-2"
    v-if="other_works != ''"
  >
    <ul class="pagination pagination-sm justify-content-end">
      <li v-if="role !== 'prorap'">
        <Select_data :id="'other_work'" />
      </li>
      <li class="page-item">
        <button
          type="button"
          class="page-link"
          @click="
            get_other_work(
              other_search,
              other_building_id,
              other_status,
              other_user_id,
              other_page,
              other_limit
            )
          "
        >
          <i class="fa-solid fa-rotate"></i>
        </button>
      </li>
      <li class="page-item">
        <button
          class="page-link"
          @click="other_page--"
          :disabled="other_page == 0"
        >
          <i class="fa-solid fa-angle-left text-dark"></i>
        </button>
      </li>
      <li class="page-item">
        <select
          class="form-control form-control-sm page_select"
          v-model="other_page"
          @change="
            get_other_work(
              other_search,
              other_building_id,
              other_status,
              other_user_id,
              other_page,
              other_limit
            )
          "
        >
          <option :value="item - 1" v-for="item in other_pages" :key="item">
            {{ item }}
          </option>
        </select>
      </li>
      <li class="page-item">
        <button
          class="page-link"
          @click="other_page++"
          :disabled="other_page == other_pages - 1"
        >
          <i class="fa-solid fa-angle-right text-dark"></i>
        </button>
      </li>
    </ul>
  </nav>

  <!-- </div>
  </div> -->

  <div
    class="modal fade"
    id="confirm_modal"
    tabindex="-1"
    aria-labelledby="confirm_modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirm_modalLabel">Тасдиқлаш</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="close"
          ></button>
        </div>
        <form @submit.prevent="confirm">
          <div class="modal-body">
            <div class="row gap-2">
              <!-- <div
                class="col-10 mx-auto"
                v-if="confirm_body.type == 'other_work'"
              >
                <div class="form-group">
                  <label for="number">Ажратилган сумма: </label>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="confirm_body.attached_price"
                      step="any"
                      min="0"
                      required
                      placeholder="Ажратилган сумма"
                    />
                    <span class="input-group-text bg-white">сўм</span>
                  </div>
                </div>
              </div> -->
              <div class="col-10 mx-auto">
                <div class="form-group">
                  <label for="file">Файл юклаш: </label>
                  <input
                    type="file"
                    class="form-control form-control-sm"
                    id="file"
                  />
                </div>
              </div>
              <div class="col-10 mx-auto">
                <div class="form-group">
                  <label for="comment10">Изох: </label>
                  <textarea
                    id="comment10"
                    placeholder="Изох"
                    v-model="confirm_body.comment"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="confirm_d"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="recheck_modal"
    tabindex="-1"
    aria-labelledby="recheck_modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="recheck_modalLabel">Қайта текширув</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="close2"
          ></button>
        </div>
        <form @submit.prevent="recheck">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <div class="form-group">
                  <label for="file">Файл юклаш: </label>
                  <input
                    type="file"
                    class="form-control form-control-sm"
                    id="file2"
                  />
                </div>
              </div>
              <div class="col-10 mx-auto">
                <div class="form-group">
                  <label for="date">Қайта текширув санаси: </label>
                  <input
                    type="date"
                    id="date"
                    class="form-control form-control-sm"
                    v-model="recheck_body.time_to_recheck"
                    required
                  />
                </div>
              </div>
              <div class="col-10 mx-auto">
                <div class="form-group">
                  <label for="comment11">Изох: </label>
                  <textarea
                    id="comment11"
                    required
                    v-model="recheck_body.comment"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="recheck_d"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="add_work">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="close_work"
          ></button>
        </div>
        <form @submit.prevent="add_work">
          <div class="modal-body">
            <div class="row gap-2 mb-2">
              <div class="col-10 mx-auto">
                <div class="btn-group btn-group-sm shadow-none w-100">
                  <button
                    type="button"
                    class="btn btn-sm"
                    :class="
                      modal_content == 'work'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    "
                    @click="modal_content = 'work'"
                  >
                    Иш тури қўшиш
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm"
                    :class="
                      modal_content == 'file'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    "
                    @click="modal_content = 'file'"
                  >
                    Файл йуклаш
                  </button>
                </div>
              </div>
            </div>
            <div class="row gap-2" v-if="modal_content == 'work'">
              <div class="col-10 mx-auto">
                <label>Номи:</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Номи"
                  v-model="work_body.name"
                  required
                />
              </div>
              <div class="col-10 mx-auto">
                <label>Ҳажми:</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  step="any"
                  placeholder="Ҳажми"
                  v-model="work_body.quantity"
                  required
                />
              </div>
              <div class="col-10 mx-auto">
                <label>Ўлчов бирлиги:</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Ўлчов бирлиги"
                  v-model="work_body.olchov_birligi"
                  required
                />
              </div>
              <div class="col-10 mx-auto">
                <label>Бошлаш сана:</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="work_body.time"
                  required
                />
              </div>
              <div class="col-10 mx-auto">
                <label>Тугаш сана:</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="work_body.end_date"
                  required
                />
              </div>
              <div class="col-10 mx-auto">
                <label>Изох:</label>
                <textarea
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Изох"
                  v-model="work_body.comment_prorap"
                />
              </div>
            </div>
            <div class="row gap-2" v-else>
              <div class="col-10 mx-auto">
                <input
                  type="file"
                  name="file-6[]"
                  id="file-6"
                  class="inputfile inputfile-5"
                  data-multiple-caption="{count} files selected"
                  multiple
                  hidden
                  @change="upload()"
                  accept=".xls,.xlsx"
                  ref="proche_file"
                />
                <label for="file-6">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                    >
                      <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                      />
                    </svg>
                  </figure>
                  <span id="proche_file_name"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="work_disabled"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="edit_work">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="close_edit"
          ></button>
        </div>
        <form @submit.prevent="edit_work()">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <label> Nomi </label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="editWork.name"
                />
              </div>
              <div class="col-10 mx-auto">
                <label> Soni </label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="editWork.quantity"
                />
              </div>
              <div class="col-10 mx-auto">
                <label> Olchov </label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="editWork.olchov_birligi"
                />
              </div>
              <div class="col-10 mx-auto">
                <label> Vaqt </label>
                <input
                  type="date"
                  class="form-control"
                  required
                  v-model="editWork.time"
                />
              </div>
              <div class="col-10 mx-auto">
                <label> Tugash vaqti </label>
                <input
                  type="date"
                  class="form-control"
                  required
                  v-model="editWork.end_date"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-outline-primary btn-sm">
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade modal-lg"
    id="addModal"
    data-mdb-keyboard="true"
    tabindex="-1"
    aria-labelledby="exampleModalToggleLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content w-full">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
            Вазифани бажариш
          </h1>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul
            class="nav nav-fill mb-3 w-100 d-flex justify-content-between"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item mx-2" role="presentation">
              <a
                class="nav-link active"
                id="pills-home-tab"
                data-mdb-toggle="pill"
                data-mdb-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                @click="
                  get_works_all_rotate(),
                    work_null(),
                    (work_add_data.type = 'other_work')
                "
              >
                Уста
              </a>
            </li>
            <li class="nav-item mx-2" role="presentation">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-mdb-toggle="pill"
                data-mdb-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                @click="
                  get_works_all_rotate(),
                    work_null(),
                    (work_add_data.type = 'machine_for_other_work')
                "
              >
                Техника
              </a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <form>
                <div class="mb-3">
                  <label for="hajm" class="form-label">Хажми</label>
                  <input
                    type="number"
                    class="form-control"
                    id="hajm"
                    v-model="work_add_data.quantity"
                    aria-describedby="emailHelp"
                    placeholder="Ҳамини киритинг..."
                  />
                </div>
                <div class="mb-3">
                  <label for="sum" class="form-label">Сумма</label>
                  <input
                    type="number"
                    class="form-control"
                    id="sum"
                    v-model="work_add_data.price"
                    placeholder="Суммани киритинг..."
                  />
                </div>

                <div class="mb-3">
                  <label for="usta" class="form-label">Усталар</label>
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      id="select_bank_account"
                      data-mdb-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                      @click="
                        work_data.data.data.length
                          ? ''
                          : get_works_all(
                              work_data.page,
                              work_data.limit,
                              work_data.search
                            )
                      "
                    >
                      {{ work_data.name }}
                    </button>
                    <ul
                      class="dropdown-menu border-top-0"
                      aria-labelledby="bank_account"
                    >
                      <li>
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder="қидириш ..."
                          v-model="work_data.search"
                          @change="
                            get_works_all(work_data.search, work_data.page)
                          "
                        />
                      </li>
                      <li>
                        <ul class="dropdown-items">
                          <li
                            v-for="item in work_data.data.data"
                            :key="item"
                            @click="
                              select_work(item.Workers.id, item.Workers.name)
                            "
                          >
                            <a class="dropdown-item">{{ item.Workers.name }}</a>
                          </li>
                        </ul>
                      </li>
                      <li v-if="bank_accounts != ''">
                        <hr class="dropdown-divider m-0" />
                      </li>
                      <li>
                        <nav
                          aria-label="Page navigation example"
                          class="mt-2"
                          v-if="bank_accounts != ''"
                        >
                          <ul
                            class="pagination pagination-sm justify-content-end"
                          >
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link"
                                @click="get_works_all_rotate()"
                              >
                                <i class="fa-solid fa-rotate"></i>
                              </button>
                            </li>
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link"
                                @click="
                                  work_data.page--,
                                    get_works_all(
                                      work_data.search,
                                      work_data.page
                                    )
                                "
                                :disabled="work_data.page == 0"
                              >
                                <i class="fa-solid fa-angle-left text-dark"></i>
                              </button>
                            </li>
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link text-dark"
                                disabled
                              >
                                {{ work_data.page + 1 }}
                              </button>
                            </li>
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link"
                                @click="
                                  work_data.page++,
                                    get_works_all(
                                      work_data.search,
                                      work_data.page
                                    )
                                "
                                :disabled="
                                  work_data.page == work_data.pages - 1
                                "
                              >
                                <i
                                  class="fa-solid fa-angle-right text-dark"
                                ></i>
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="comment" class="form-label">Изоҳ</label>
                  <input
                    type="text"
                    class="form-control"
                    id="comment"
                    v-model="work_add_data.comment"
                    placeholder="Изоҳ киритинг..."
                  />
                </div>
                <div class="d-flex items-center justify-content-center">
                  <vue3starRatings v-model="work_add_data.rating" />
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <form>
                <div class="mb-3">
                  <label for="tex" class="form-label">Техника</label>
                  <input
                    type="text"
                    class="form-control"
                    id="tex"
                    aria-describedby="emailHelp"
                    placeholder="Техника номини киритинг..."
                    v-model="work_add_data.item"
                  />
                </div>
                <div class="mb-3">
                  <label for="hajm" class="form-label">Хажми</label>
                  <input
                    type="number"
                    class="form-control"
                    id="hajm"
                    aria-describedby="emailHelp"
                    placeholder="Ҳамини киритинг..."
                    v-model="work_add_data.quantity"
                  />
                </div>
                <div class="mb-3">
                  <label for="vaqt" class="form-label">Сарфланган вақт</label>
                  <input
                    type="number"
                    class="form-control"
                    id="vaqt"
                    aria-describedby="emailHelp"
                    placeholder="Сарфланган вақтни киритинг..."
                    v-model="work_add_data.time_spent"
                  />
                </div>
                <div class="mb-3">
                  <label for="sum" class="form-label">Сумма</label>
                  <input
                    type="number"
                    class="form-control"
                    id="sum"
                    placeholder="Суммани киритинг..."
                    v-model="work_add_data.price"
                  />
                </div>

                <div class="mb-3">
                  <label for="usta" class="form-label">Усталар</label>
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button"
                      id="select_bank_account"
                      data-mdb-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                      @click="
                        work_data.data.data.length
                          ? ''
                          : get_works_all(
                              work_data.page,
                              work_data.limit,
                              work_data.search
                            )
                      "
                    >
                      {{ work_data.name }}
                    </button>
                    <ul
                      class="dropdown-menu border-top-0"
                      aria-labelledby="bank_account"
                    >
                      <li>
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder="қидириш ..."
                          v-model="work_data.search"
                          @change="
                            get_works_all(work_data.search, work_data.page)
                          "
                        />
                      </li>
                      <li>
                        <ul class="dropdown-items">
                          <li
                            v-for="item in work_data.data.data"
                            :key="item"
                            @click="
                              select_work(item.Workers.id, item.Workers.name)
                            "
                          >
                            <a class="dropdown-item">{{ item.Workers.name }}</a>
                          </li>
                        </ul>
                      </li>
                      <li v-if="bank_accounts != ''">
                        <hr class="dropdown-divider m-0" />
                      </li>
                      <li>
                        <nav
                          aria-label="Page navigation example"
                          class="mt-2"
                          v-if="bank_accounts != ''"
                        >
                          <ul
                            class="pagination pagination-sm justify-content-end"
                          >
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link"
                                @click="get_works_all_rotate()"
                              >
                                <i class="fa-solid fa-rotate"></i>
                              </button>
                            </li>
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link"
                                @click="
                                  work_data.page--,
                                    get_works_all(
                                      work_data.search,
                                      work_data.page
                                    )
                                "
                                :disabled="work_data.page == 0"
                              >
                                <i class="fa-solid fa-angle-left text-dark"></i>
                              </button>
                            </li>
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link text-dark"
                                disabled
                              >
                                {{ work_data.page + 1 }}
                              </button>
                            </li>
                            <li class="page-item">
                              <button
                                type="button"
                                class="page-link"
                                @click="
                                  work_data.page++,
                                    get_works_all(
                                      work_data.search,
                                      work_data.page
                                    )
                                "
                                :disabled="
                                  work_data.page == work_data.pages - 1
                                "
                              >
                                <i
                                  class="fa-solid fa-angle-right text-dark"
                                ></i>
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="comment" class="form-label">Изоҳ</label>
                  <input
                    type="text"
                    class="form-control"
                    id="comment"
                    placeholder="Изоҳ киритинг..."
                    v-model="work_add_data.comment"
                  />
                </div>
                <div class="d-flex items-center justify-content-center">
                  <vue3starRatings v-model="work_add_data.rating" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            id="work_add_data_close"
            data-mdb-dismiss="modal"
          >
            Ёпиш
          </button>
          <button type="button" class="btn btn-primary" @click="work_add()">
            Юбориш
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="product"
    data-mdb-keyboard="false"
    tabindex="-1"
    aria-labelledby="productLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content w-full">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="productLabel">Маҳсулот сарфлаш</h1>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="product_consumption_add" id="submit_form">
            <div class="mb-3">
              <label for="usta" class="form-label">Маҳсулотлар</label>
              <div class="dropdown">
                <button
                  class="btn btn-light btn-sm dropdown-toggle"
                  type="button"
                  id="select_bank_account"
                  data-mdb-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                  @click="
                    modal_product_select.data.data.length
                      ? ''
                      : get_modal_product_all(
                          modal_product_select.page,
                          modal_product_select.limit,
                          modal_product_select.search
                        )
                  "
                >
                  {{ product_consumption.name }}
                </button>
                <ul
                  class="dropdown-menu border-top-0"
                  aria-labelledby="bank_account"
                >
                  <li>
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder="қидириш ..."
                      v-model="modal_product_select.search"
                      @change="
                        get_modal_product_all(
                          modal_product_select.search,
                          modal_product_select.page
                        )
                      "
                    />
                  </li>
                  <li>
                    <ul class="dropdown-items">
                      <li @click="select(0, 'барчаси')">
                        <a class="dropdown-item">барчаси</a>
                      </li>
                      <li
                        v-for="item in modal_product_select.data.data"
                        :key="item"
                        @click="
                          select(
                            item.Warehouse_products.id,
                            item.Warehouse_products.name
                          )
                        "
                      >
                        <a class="dropdown-item">{{
                          item.Warehouse_products.name
                        }}</a>
                      </li>
                    </ul>
                  </li>
                  <li v-if="bank_accounts != ''">
                    <hr class="dropdown-divider m-0" />
                  </li>
                  <li>
                    <nav
                      aria-label="Page navigation example"
                      class="mt-2"
                      v-if="bank_accounts != ''"
                    >
                      <ul class="pagination pagination-sm justify-content-end">
                        <li class="page-item">
                          <button
                            type="button"
                            class="page-link"
                            @click="get_modal_product_all_rotate()"
                          >
                            <i class="fa-solid fa-rotate"></i>
                          </button>
                        </li>
                        <li class="page-item">
                          <button
                            type="button"
                            class="page-link"
                            @click="
                              modal_product_select.page--,
                                get_modal_product_all(
                                  modal_product_select.search,
                                  modal_product_select.page
                                )
                            "
                            :disabled="modal_product_select.page == 0"
                          >
                            <i class="fa-solid fa-angle-left text-dark"></i>
                          </button>
                        </li>
                        <li class="page-item">
                          <button
                            type="button"
                            class="page-link text-dark"
                            disabled
                          >
                            {{ modal_product_select.page + 1 }}
                          </button>
                        </li>
                        <li class="page-item">
                          <button
                            type="button"
                            class="page-link"
                            @click="
                              modal_product_select.page++,
                                get_modal_product_all(
                                  modal_product_select.search,
                                  modal_product_select.page
                                )
                            "
                            :disabled="
                              modal_product_select.page ==
                              modal_product_select.pages - 1
                            "
                          >
                            <i class="fa-solid fa-angle-right text-dark"></i>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mb-3">
              <label for="hajm" class="form-label">Хажми</label>
              <input
                type="text"
                class="form-control"
                id="hajm"
                aria-describedby="emailHelp"
                placeholder="Ҳамини киритинг..."
                v-model="product_consumption.quantity"
              />
            </div>
            <div class="mb-3">
              <label for="comment" class="form-label">Изоҳ</label>
              <textarea
                type="text"
                class="form-control"
                id="comment"
                placeholder="Изоҳ киритинг..."
                v-model="product_consumption.comment"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="product_consumption_close"
            class="btn btn-secondary"
            data-mdb-dismiss="modal"
          >
            Ёпиш
          </button>
          <button type="submit" class="btn btn-primary" form="submit_form">
            Юбориш
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header_item from "@/components/header/header_item.vue";
import {
  add_other_work,
  create_proche_work,
  other_works,
  remove_other_work,
  smeta_get,
  work_confirmation,
  update_other_work,
  warehouse_products,
  workers_get,
  spent_work_add,
} from "@/utils/request/request";
import {
  catchError,
  link,
  success,
  countDays,
} from "@/utils/request/handle_api_err";
import Data_notfound from "@/utils/notfound/data_notfound.vue";
import Format_numbers from "@/utils/format/format_numbers.vue";
import Select_data from "@/utils/select_data/select_data.vue";
import Diagramma from "@/components/buildings/diagramma.vue";
import vue3starRatings from "vue3-star-ratings";

export default {
  name: "BuildingWorks",
  components: {
    Header_item,
    Data_notfound,
    Format_numbers,
    Select_data,
    Diagramma,
    vue3starRatings,
  },
  data() {
    return {
      smeta_building_id: this.$route.query.id,
      smeta_limit: 25,
      smeta_page: 0,
      smeta_pages: null,
      smeta_search: "",
      smeta_status: "wait",
      smeta_type: "grafik",
      smeta_order_by: "",
      smeta_works: [],
      other_search: "",
      other_building_id: this.$route.query.id,
      other_status: "",
      other_user_id: 0,
      other_page: 0,
      other_limit: 10,
      other_pages: null,
      other_works: [],
      work_id: null,
      confirm_body: {
        type: "smeta_work",
        status: true,
        file: "",
        comment: "",
        time_to_recheck: "",
        attached_price: 0,
      },
      recheck_body: {
        type: "smeta_work",
        status: false,
        file: "",
        comment: "",
        time_to_recheck: "",
        attached_price: 0,
      },
      date: new Date().toISOString().slice(0, 10),
      role: localStorage.getItem("role"),
      work_body: {
        category_other_work_id: this.$route.query.source_id,
        name: "",
        quantity: 0,
        olchov_birligi: "",
        comment_prorap: "",
        building_id: this.$route.query.id,
        time: "",
        end_date: "",
      },
      count_days: countDays,
      modal_content: "work",
      data: {
        id: this.$route.query.id,
        source_id: this.$route.query.source_id,
        file: "",
      },
      editWork: {
        id: 0,
        name: "",
        quantity: 0,
        olchov_birligi: "",
        comment_prorap: "",
        time: "",
        end_date: "",
      },
      modal_product_select: {
        type: "proche",
        building_id: this.$route.query.id,
        search: "",
        status: "warehouse",
        page: 0,
        limit: 25,
        pages: 1,
        data: {},
      },
      work_data: {
        building_id: this.$route.query.id,
        search: "",
        page: 0,
        pages: 1,
        limit: 25,
        name: "Усталар",
        data: {},
      },
      work_add_data: {
        work_id: "",
        worker_id: 0,
        item: "",
        quantity: "",
        time_spent: 0,
        price: "",
        type: "other_work",
        comment: "",
        rating: 0,
      },
      product_consumption: {
        name: "Маҳсулотлар",
        work_id: 0,
        worker_id: 0,
        item: 0,
        quantity: 0,
        time_spent: 0,
        price: 0,
        type: "w_product_for_other_work",
        rating: 0,
        comment: "",
      },
      paid_status: false,
    };
  },
  watch: {
    smeta_limit(e) {
      this.smeta_page = 0;
      this.get_smeta_work(
        this.smeta_page,
        e,
        this.smeta_search,
        this.smeta_type,
        this.smeta_status,
        this.smeta_building_id,
        this.smeta_order_by
      );
    },
    smeta_page(e) {
      this.get_smeta_work(
        e,
        this.smeta_limit,
        this.smeta_search,
        this.smeta_type,
        this.smeta_status,
        this.smeta_building_id,
        this.smeta_order_by
      );
    },
    smeta_search(e) {
      this.smeta_page = 0;
      this.get_smeta_work(
        this.smeta_page,
        this.smeta_limit,
        e,
        this.smeta_type,
        this.smeta_status,
        this.smeta_building_id,
        this.smeta_order_by
      );
    },
    smeta_status(e) {
      this.smeta_page = 0;
      this.get_smeta_work(
        this.smeta_page,
        this.smeta_limit,
        this.smeta_search,
        this.smeta_type,
        e,
        this.smeta_building_id,
        this.smeta_order_by
      );
    },
    other_search(e) {
      this.other_page = 0;
      this.get_other_work(
        e,
        this.other_building_id,
        this.other_status,
        this.other_user_id,
        this.other_page,
        this.other_limit
      );
    },
    other_page(e) {
      this.get_other_work(
        this.other_search,
        this.other_building_id,
        this.other_status,
        this.other_user_id,
        e,
        this.other_limit
      );
    },
    other_status(e) {
      this.other_page = 0;
      this.get_other_work(
        this.other_search,
        this.other_building_id,
        e,
        this.other_user_id,
        this.other_page,
        this.other_limit
      );
    },
  },
  methods: {
    get_smeta_work(
      page,
      limit,
      search,
      type,
      status,
      building_id,
      smeta_order_by
    ) {
      this.$store.dispatch("setLoading", { isLoading: true });
      smeta_get(page, limit, search, type, status, building_id, smeta_order_by)
        .then((res) => {
          this.smeta_works = res.data.data;
          this.smeta_pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    get_other_work(search, building_id, status, user_id, page, limit) {
      this.$store.dispatch("setLoading", { isLoading: true });
      other_works(
        search,
        building_id,
        status,
        user_id,
        page,
        limit,
        this.$route.query.source_id,
        this.paid_status
      )
        .then((res) => {
          this.role == "prorap"
            ? ""
            : this.$refs.diagramma.start(res.data.data);
          this.other_works = res.data.data;
          this.other_pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    confirm() {
      let file = document.getElementById("file").files[0];
      if (file) {
        this.confirm_body.file = file;
      } else {
        this.confirm_body.file = "";
      }
      let data = new FormData();
      data.append("type", this.confirm_body.type);
      data.append("status", this.confirm_body.status);
      data.append("file", this.confirm_body.file);
      data.append("comment", this.confirm_body.comment);
      data.append("time_to_recheck", this.confirm_body.time_to_recheck);
      data.append("attached_price", this.confirm_body.attached_price);
      document.getElementById("confirm_d").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });
      work_confirmation(this.work_id, data)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("close").click();
          document.getElementById("confirm_d").disabled = false;
          success(res);
          if (this.confirm_body.type == "smeta_work") {
            this.get_smeta_work(
              this.smeta_page,
              this.smeta_limit,
              this.smeta_search,
              this.smeta_type,
              this.smeta_status,
              this.smeta_building_id,
              this.smeta_order_by
            );
          } else {
            this.get_other_work(
              this.other_search,
              this.other_building_id,
              this.other_status,
              this.other_user_id,
              this.other_page,
              this.other_limit
            );
          }
          this.confirm_body.comment = "";
          this.confirm_body.file = "";
          document.getElementById("file").value = null;
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("confirm_d").disabled = false;
        });
    },
    recheck() {
      let file = document.getElementById("file2").files[0];
      if (file) {
        this.recheck_body.file = file;
      } else {
        this.recheck_body.file = "";
      }
      let data = new FormData();
      data.append("type", this.recheck_body.type);
      data.append("status", this.recheck_body.status);
      data.append("file", this.recheck_body.file);
      data.append("comment", this.recheck_body.comment);
      data.append("time_to_recheck", this.recheck_body.time_to_recheck);
      data.append("attached_price", this.recheck_body.attached_price);
      document.getElementById("recheck_d").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });
      work_confirmation(this.work_id, data)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("close2").click();
          document.getElementById("recheck_d").disabled = false;
          success(res);
          if (this.confirm_body.type == "smeta_work") {
            this.get_smeta_work(
              this.smeta_page,
              this.smeta_limit,
              this.smeta_search,
              this.smeta_type,
              this.smeta_status,
              this.smeta_building_id,
              this.smeta_order_by
            );
          } else {
            this.get_other_work(
              this.other_search,
              this.other_building_id,
              this.other_status,
              this.other_user_id,
              this.other_page,
              this.other_limit
            );
          }
          this.recheck_body.comment = "";
          this.recheck_body.file = "";
          document.getElementById("file2").value = null;
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("recheck_d").disabled = false;
        });
    },
    remove(e, type) {
      let data = new FormData();
      data.append("type", type);
      data.append("status", "");
      data.append("file", "");
      data.append("comment", "");
      data.append("time_to_recheck", "");
      data.append("attached_price", "");
      this.$store.dispatch("setLoading", { isLoading: true });
      work_confirmation(e, data)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          success(res);
          if (type == "smeta_work_remove_confirmed") {
            this.get_smeta_work(
              this.smeta_page,
              this.smeta_limit,
              this.smeta_search,
              this.smeta_type,
              this.smeta_status,
              this.smeta_building_id,
              this.smeta_order_by
            );
          } else {
            this.get_other_work(
              this.other_search,
              this.other_building_id,
              this.other_status,
              this.other_user_id,
              this.other_page,
              this.other_limit
            );
          }
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    remove_work(id) {
      this.$store.dispatch("setLoading", { isLoading: true });
      remove_other_work(id)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          success(res);
          this.get_other_work(
            this.other_search,
            this.other_building_id,
            this.other_status,
            this.other_user_id,
            this.other_page,
            this.other_limit
          );
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    date_sort(e) {
      let date1 = new Date(e);
      let date2 = new Date(this.date);

      const diffTime = Math.abs(date1 - date2);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return Number(diffDays);
    },
    add_work() {
      document.getElementById("work_disabled").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });

      let add_request =
        this.modal_content == "work" ? add_other_work : create_proche_work;

      let body = this.modal_content == "work" ? this.work_body : this.data;

      add_request(body)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("close_work").click();
          document.getElementById("work_disabled").disabled = false;
          success(res);
          this.get_other_work(
            this.other_search,
            this.other_building_id,
            this.other_status,
            this.other_user_id,
            this.other_page,
            this.other_limit
          );
          this.modal_content == "work"
            ? (this.work_body = {
                category_other_work_id: this.$route.query.source_id,
                name: "",
                quantity: 0,
                olchov_birligi: "",
                comment_prorap: "",
                building_id: this.$route.query.id,
                time: "",
                end_date: "",
              })
            : ((this.data.file = ""),
              (document.getElementById("file-6").value = null),
              (document.getElementById("file_name").innerHTML = ""));
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("work_disabled").disabled = false;
        });
    },
    upload() {
      this.data.file = this.$refs.proche_file.files[0];
      document.getElementById("proche_file_name").innerHTML =
        this.$refs.proche_file.files[0]?.name;
    },
    edit_work() {
      this.$store.dispatch("setLoading", { isLoading: true });

      update_other_work(this.editWork)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("close_edit").click();
          success(res);
          this.get_other_work(
            this.other_search,
            this.other_building_id,
            this.other_status,
            this.other_user_id,
            this.other_page,
            this.other_limit
          );
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("work_disabled").disabled = false;
        });
    },
    get_modal_product(type, building_id, search, status, page, limit) {
      this.$store.dispatch("setLoading", { isLoading: true });
      warehouse_products(type, building_id, search, status, page, limit)
        .then((res) => {
          this.modal_product_select.data = res.data;
          this.modal_product_select.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    select(id, name) {
      this.product_consumption.name = name;
      this.product_consumption.item = id;
    },
    get_modal_product_all(search, page) {
      this.modal_product_select.search = search;
      this.modal_product_select.page = page;
      this.get_modal_product(
        this.modal_product_select.type,
        this.modal_product_select.building_id,
        this.modal_product_select.search,
        this.modal_product_select.status,
        this.modal_product_select.page,
        this.modal_product_select.limit
      );
    },
    get_modal_product_all_rotate() {
      this.modal_product_select.search = "";
      this.modal_product_select.page = 0;
      this.get_modal_product(
        this.modal_product_select.type,
        this.modal_product_select.building_id,
        this.modal_product_select.search,
        this.modal_product_select.status,
        this.modal_product_select.page,
        this.modal_product_select.limit
      );
    },
    get_works(page, limit, building_id, search) {
      workers_get(page, limit, search, building_id)
        .then((res) => {
          this.work_data.data = res.data;
          this.work_data.pages = res.data.pages;
        })
        .catch((err) => {
          catchError(err);
        });
    },
    get_works_all(search, page) {
      this.work_data.search = search;
      this.work_data.page = page;
      this.get_works(
        this.work_data.page,
        this.work_data.limit,
        this.work_data.building_id,
        this.work_data.search
      );
    },
    get_works_all_rotate() {
      this.work_data.search = "";
      this.work_data.page = 0;
      this.get_works(
        this.work_data.page,
        this.work_data.limit,
        this.work_data.building_id,
        this.work_data.search
      );
    },
    select_work(id, name) {
      this.work_data.name = name;
      this.work_add_data.worker_id = id;
    },
    work_add() {
      this.$store.dispatch("setLoading", { isLoading: true });
      this.work_add_data.rating = Math.round(this.work_add_data.rating);
      spent_work_add(this.work_add_data)
        .then((res) => {
          document.getElementById("work_add_data_close").click();
          success(res);
          (this.work_add_data = {
            work_id: "",
            worker_id: 0,
            item: "",
            quantity: "",
            time_spent: 0,
            price: "",
            type: "other_work",
            comment: "",
            rating: 0,
          }),
            this.$store.dispatch("setLoading", { isLoading: false });
          this.get_other_work(
            this.other_search,
            this.other_building_id,
            this.other_status,
            this.other_user_id,
            this.other_page,
            this.other_limit
          );
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    product_consumption_add() {
      this.$store.dispatch("setLoading", { isLoading: true });
      spent_work_add(this.product_consumption)
        .then((res) => {
          document.getElementById("product_consumption_close").click();
          success(res);
          this.product_consumption = {
            name: "Маҳсулотлар",
            work_id: 0,
            worker_id: 0,
            item: 0,
            quantity: 0,
            time_spent: 0,
            price: 0,
            type: "w_product_for_smeta_work",
            rating: 0,
            comment: "",
          };
          this.$store.dispatch("setLoading", { isLoading: false });
          this.get_other_work(
            this.other_search,
            this.other_building_id,
            this.other_status,
            this.other_user_id,
            this.other_page,
            this.other_limit
          );
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    work_null() {
      this.work_add_data.work_id = 0;
      this.work_add_data.worker_id = 0;
      this.work_add_data.item = "";
      this.work_add_data.quantity = "";
      this.work_add_data.time_spent = 0;
      this.work_add_data.price = "";
      this.work_add_data.type = "other_work";
      this.work_add_data.comment = "";
      this.work_add_data.rating = 0;
      this.work_data.name = "Усталар";
      this.work_add_data.work_id = 0;
    },
  },
  created() {
    this.confirm_body.type = "other_work";
    this.recheck_body.type = "other_work";
    this.get_other_work(
      this.other_search,
      this.other_building_id,
      this.other_status,
      this.other_user_id,
      this.other_page,
      this.other_limit
    );
    // this.get_smeta_work(
    //   this.smeta_page,
    //   this.smeta_limit,
    //   this.smeta_search,
    //   this.smeta_type,
    //   this.smeta_status,
    //   this.smeta_building_id,
    //   this.smeta_order_by
    // );
    this.get_modal_product(
      this.modal_product_select.type,
      this.modal_product_select.building_id,
      this.modal_product_select.search,
      this.modal_product_select.status,
      this.modal_product_select.page,
      this.modal_product_select.limit
    );
    this.get_works(
      this.work_data.page,
      this.work_data.limit,
      this.work_data.building_id,
      this.work_data.search
    );
  },

  computed: {
    img_path() {
      return link;
    },
  },
};
</script>

<style lang="scss" scoped>
.col select {
  width: 80px;
  height: 30px;
}

.col select:last-child {
  width: 130px;
  height: 30px;
}

.col .input-group {
  width: 200px;
}

@media (max-width: 632px) {
  .row {
    display: block;
  }

  .row .col .input-group {
    width: 600px;
  }

  .row .col select {
    width: 50%;
  }
}

table.work {
  min-width: 1900px;
  width: 100%;
  zoom: 75%;
}

table.other_work {
  min-width: 1700px;
  width: 100%;
  zoom: 75%;
}

.table-responsive {
  max-height: 58vh;
  min-height: 58vh;
}

@media (max-width: 500px) {
  .table-responsive {
    max-height: 78vh;
    min-height: 78vh;
  }
}

.inputfile-5 + label {
  color: #31be7d;
  display: block;
  justify-content: center;
  text-align: center;
}

.inputfile-5:focus + label,
.inputfile-5.has-focus + label,
.inputfile-5 + label:hover {
  color: #1f6e43;
}

.inputfile-5 + label figure {
  width: 100px;
  height: 135px;
  background-color: #31be7d;
  display: block;
  position: relative;
  padding: 30px;
  margin: 0 auto 10px;
}

.inputfile-5:focus + label figure,
.inputfile-5.has-focus + label figure,
.inputfile-5 + label:hover figure {
  background-color: #1f6e43;
}

.inputfile-5 + label figure::before,
.inputfile-5 + label figure::after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}

.inputfile-5 + label figure::before {
  border-top: 20px solid #dfc8ca;
  border-left: 20px solid transparent;
}

.inputfile-5 + label figure::after {
  border-bottom: 20px solid #1f6e43;
  border-right: 20px solid transparent;
}

.inputfile-5:focus + label figure::after,
.inputfile-5.has-focus + label figure::after,
.inputfile-5 + label:hover figure::after {
  border-bottom-color: #31be7d;
}

.inputfile-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
