<template>
  <div class="table-responsive mt-3">
    <table class="table table-sm table-hover" id="worker_item">
      <thead class="">
        <tr>
          <th style="width: 8%">№</th>
          <th style="width: 20%">Исм</th>
          <th style="width: 20%">Телефон рақам</th>
          <th style="width: 25%">Ихтисослик</th>
          <th style="width: 12%">Рейтинг</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, inc) in data || []" :key="item">
          <td>{{ inc + 1 }}</td>
          <td>{{ item?.Workers.name }}</td>
          <td>
            <a
              :href="`tel:+998${item?.Workers.phone}`"
              class="text-decoration-none text-dark"
            >
              +998 {{ format_phone(item?.Workers.phone) }}</a
            >
          </td>
          <td>
            <span
              @click="$emit('remove_specialty', worker_item.id)"
              class="badge rounded-pill bg-danger mx-1"
              v-for="worker_item in item.specialties"
              :key="worker_item"
              ><i class="fa-solid fa-circle-xmark text-danger text-white"></i>
              {{ worker_item.specialty_type.specialty }}</span
            >
          </td>
          <td data-exclude="true">
            <label class="rating-label">
              <input
                class="rating"
                max="5"
                oninput="this.style.setProperty('--value', `${this.valueAsNumber}`)"
                step="0.1"
                :style="'--value:' + item.rating"
                type="range"
                disabled
              />
            </label>
          </td>
          <td data-exclude="true">
            <button
              class="btn btn-sm btn-warning"
              @click="$emit('item', item)"
              data-mdb-toggle="modal"
              data-mdb-target="#worker_put"
            >
              <i class="fa fa-edit text-white"></i>
            </button>
            <button
              class="btn btn-sm btn-primary mx-1"
              @click="$emit('item', item)"
              data-mdb-toggle="modal"
              data-mdb-target="#worker_speciality_add"
            >
              <i class="fa-solid fa-circle-plus text-white"></i>
            </button>
            <router-link
              :to="{
                path: '/worker_history',
                query: {
                  id: item.Workers.id,
                  name: item.Workers.name,
                  building_id: $route.query.id,
                },
              }"
              v-if="role == 'prorap'"
              class="btn btn-primary btn-sm mx-1"
            >
              <i class="fa-solid fa-clock-rotate-left"></i>
            </router-link>
            <button
              v-if="role == 'prorap'"
              class="btn btn-success btn-sm mx-1"
              @click="money_data.to_ = item.Workers.id"
              data-mdb-toggle="modal"
              data-mdb-target="#money_pluck"
            >
              <i class="fa-solid fa-money-check-dollar"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div class="modal fade" id="money_pluck">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Пул тўлаш</h5>
            <button
              type="button"
              class="btn-close"
              data-mdb-dismiss="modal"
              aria-label="Close"
              id="worker_close"
            ></button>
          </div>
          <form @submit.prevent="worker_money_add()">
            <div class="modal-body">
              <div class="row gap-2">
                <div class="col-10 mx-auto">
                  <label for="sum"> Сумма: </label>
                  <input
                    type="number"
                    id="sum"
                    class="form-control form-control-sm"
                    v-model="money_data.money"
                    required
                    placeholder="Суммани киритинг..."
                  />
                </div>
                <div class="col-10 mx-auto">
                  <label for="izoh"> Изоҳ: </label>
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      id="izoh"
                      class="form-control form-control-sm"
                      required
                      v-model="money_data.comment"
                      placeholder="Изоҳ киритинг..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-outline-primary btn-sm"
                id="worker_disabled"
                @click="worker_money_add()"
                :disabled="money_data.comment === '' && money_data.money === ''"
              >
                <i class="fa-solid fa-circle-check"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                data-mdb-dismiss="modal"
                id="close"
              >
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { worker_money_add } from "@/utils/request/request";
import { catchError, success } from "@/utils/request/handle_api_err";

export default {
  name: "WorkersItems",
  props: ["data"],
  emits: ["item", "remove_specialty"],
  data() {
    return {
      role: localStorage.getItem("role"),
      money_data: {
        money: "",
        from_: 0,
        to_: 0,
        for_: 0,
        comment: "",
      },
    };
  },
  methods: {
    format_phone(number) {
      return String(
        "(" +
          String(number).substr(0, 2) +
          ") " +
          String(number).substr(2, 3) +
          " " +
          String(number).substr(5, 2) +
          " " +
          String(number).substr(7, 2)
      );
    },
    worker_money_add() {
      this.$store.dispatch("setLoading", { isLoading: true });
      let arr = [];
      arr.push(this.money_data);
      worker_money_add(arr)
        .then((res) => {
          success(res);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("close").click();
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  min-width: 1250px;
  width: 100%;
}

.table-responsive {
  max-height: 66vh;
}

@media (max-width: 500px) {
  .table-responsive {
    max-height: 85vh;
  }
}

// rating

.rating {
  --dir: right;
  --fill: gold;
  --fillbg: rgba(100, 100, 100, 0.15);
  --star: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"/></svg>');
  --stars: 5;
  --starsize: 1.5rem;
  --symbol: var(--star);
  --value: 1;
  --w: calc(var(--stars) * var(--starsize));
  --x: calc(100% * (var(--value) / var(--stars)));
  block-size: var(--starsize);
  inline-size: var(--w);
  /* position: relative; */
  touch-action: manipulation;
  -webkit-appearance: none;
}
[dir="rtl"] .rating {
  --dir: left;
}
.rating::-moz-range-track {
  background: linear-gradient(
    to var(--dir),
    var(--fill) 0 var(--x),
    var(--fillbg) 0 var(--x)
  );
  block-size: 100%;
  mask: repeat left center/var(--starsize) var(--symbol);
}
.rating::-webkit-slider-runnable-track {
  background: linear-gradient(
    to var(--dir),
    var(--fill) 0 var(--x),
    var(--fillbg) 0 var(--x)
  );
  block-size: 100%;
  mask: repeat left center/var(--starsize) var(--symbol);
  -webkit-mask: repeat left center/var(--starsize) var(--symbol);
}
.rating::-moz-range-thumb {
  height: var(--starsize);
  opacity: 0;
  width: var(--starsize);
}
.rating::-webkit-slider-thumb {
  height: var(--starsize);
  opacity: 0;
  width: var(--starsize);
  -webkit-appearance: none;
}
.rating,
.rating-label {
  margin-bottom: 0px !important;
  /* display: block; */
  font-family: ui-sans-serif, system-ui, sans-serif;
}

/* NO JS */
.rating--nojs::-moz-range-track {
  background: var(--fillbg);
}
.rating--nojs::-moz-range-progress {
  background: var(--fill);
  block-size: 100%;
  mask: repeat left center/var(--starsize) var(--star);
}
.rating--nojs::-webkit-slider-runnable-track {
  background: var(--fillbg);
}
.rating--nojs::-webkit-slider-thumb {
  background-color: var(--fill);
  box-shadow: calc(0rem - var(--w)) 0 0 var(--w) var(--fill);
  opacity: 1;
  width: 1px;
}
[dir="rtl"] .rating--nojs::-webkit-slider-thumb {
  box-shadow: var(--w) 0 0 var(--w) var(--fill);
}

td span {
  cursor: pointer;
}
</style>
