<template>
  <Header_item :name="this.$route.query.name" />
  <div class="row my-2">
    <div class="col"></div>
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-light btn-sm dropdown-toggle"
          type="button"
          id="select_bank_account"
          data-mdb-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
          @click="get_works(work_data.page, work_data.limit)"
        >
          {{ work_data.name }}
        </button>
        <ul class="dropdown-menu border-top-0" aria-labelledby="bank_account">
          <li>
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="қидириш ..."
              v-model="work_data.search"
              @change="get_works(work_data.page, work_data.limit)"
            />
          </li>
          <li>
            <ul class="dropdown-items">
              <li
                @click="
                  work_data.name = 'Barchasi';
                  get_work_info(
                    this.work_id,
                    0,
                    this.type,
                    this.user_id,
                    this.page,
                    this.limit
                  );
                "
              >
                <a class="dropdown-item">Barchasi</a>
              </li>
              <li
                v-for="item in work_data.data.data"
                :key="item"
                @click="
                  work_data.name = item.Workers.name;
                  get_work_info(
                    this.work_id,
                    item.Workers.id,
                    this.type,
                    this.user_id,
                    this.page,
                    this.limit
                  );
                "
              >
                <a class="dropdown-item">{{ item.Workers.name }}</a>
              </li>
            </ul>
          </li>
          <li v-if="bank_accounts != ''">
            <hr class="dropdown-divider m-0" />
          </li>
          <li>
            <nav
              aria-label="Page navigation example"
              class="mt-2"
              v-if="bank_accounts != ''"
            >
              <ul class="pagination pagination-sm justify-content-end">
                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    @click="get_works_all_rotate()"
                  >
                    <i class="fa-solid fa-rotate"></i>
                  </button>
                </li>
                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    @click="
                      work_data.page--,
                        get_works(work_data.page, work_data.limit)
                    "
                    :disabled="work_data.page == 0"
                  >
                    <i class="fa-solid fa-angle-left text-dark"></i>
                  </button>
                </li>
                <li class="page-item">
                  <button type="button" class="page-link text-dark" disabled>
                    {{ work_data.page + 1 }}
                  </button>
                </li>
                <li class="page-item">
                  <button
                    type="button"
                    class="page-link"
                    @click="
                      work_data.page++,
                        get_works(work_data.page, work_data.limit)
                    "
                    :disabled="work_data.page == work_data.pages - 1"
                  >
                    <i class="fa-solid fa-angle-right text-dark"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-sm table-hover" id="building_info">
      <thead>
        <tr>
          <th>№</th>
          <th>Уста</th>
          <th>Иш тури</th>
          <th>Сумма</th>
          <th>Чиқим</th>
          <th>Қолдиқ</th>
          <th>Ҳажм</th>
          <th>Ўлчов бирлиги</th>
          <th>Сана</th>
          <th>Техника/Материал</th>
          <th>Сарфланган соат</th>
          <th>Прорап</th>
          <th data-exclude="true" v-if="!building_id">
            <select class="form-select form-select-sm" v-model="type">
              <option :value="this.$route.query.type">
                {{
                  this.$route.query.type == "smeta_work"
                    ? "сметадаги иш"
                    : "сметадан ташқари иш"
                }}
              </option>
              <option
                :value="
                  this.$route.query.type == 'smeta_work'
                    ? 'machine_for_smeta_work'
                    : 'machine_for_other_work'
                "
              >
                {{ "сарфланган техника" }}
              </option>
              <option
                :value="
                  this.$route.query.type == 'smeta_work'
                    ? 'w_product_for_smeta_work'
                    : 'w_product_for_other_work'
                "
              >
                {{ "сарфланган материал" }}
              </option>
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, inc) in info" :key="item">
          <td>{{ inc + 1 }}</td>
          <td>{{ item.worker }}</td>
          <td>{{ item.work.split("=>", 4)[0] }}</td>
          <td>
            {{ new Intl.NumberFormat().format(item.Spent_items_to_work.price) }}
            сўм
          </td>
          <td>
            {{ new Intl.NumberFormat().format(item.sum_expense) }}
            сўм
          </td>
          <td>
            {{
              new Intl.NumberFormat().format(
                item.Spent_items_to_work.price *
                  (item.Spent_items_to_work.type == "machine_for_smeta_work" ||
                  item.Spent_items_to_work.type == "machine_for_other_work"
                    ? item.Spent_items_to_work.time_spent
                    : item.Spent_items_to_work.quantity)
              )
            }}
            /
            {{
              new Intl.NumberFormat().format(
                item.Spent_items_to_work.price *
                  (item.Spent_items_to_work.type == "machine_for_smeta_work" ||
                  item.Spent_items_to_work.type == "machine_for_other_work"
                    ? item.Spent_items_to_work.time_spent
                    : item.Spent_items_to_work.quantity) -
                  item.sum_expense
              )
            }}
          </td>
          <td>
            <Format_numbers
              :number="
                type == 'w_product_for_other_work'
                  ? item.sum_quantity
                  : item.Spent_items_to_work.quantity
              "
            />
          </td>
          <td>
            {{
              type == "w_product_for_other_work"
                ? item.item.split("//=>//", 4)[1]
                : $route.query.olchov
            }}
          </td>
          <td>
            {{
              new Date(item.Spent_items_to_work.time)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>
            <!-- {{
              item.Spent_items_to_work.type == "machine_for_smeta_work" ||
              item.Spent_items_to_work.type == "machine_for_other_work" ||
              item.Spent_items_to_work.type == "w_product_for_other_work" ||
              item.Spent_items_to_work.type == "w_product_for_smeta_work"
                ? item.item
                : ""
            }} -->
            <!-- {{ item.item }} -->
            {{
              item.item.split("//=>//", 4)[3] == "smeta"
                ? item.item.split("//=>//", 4)[0]
                : item.item.split("//=>//", 4)[3] == "tarkib"
                ? item.item.split("//=>//", 4)[0] +
                  ":" +
                  item.item.split("//=>//", 4)[1]
                : item.item.split("//=>//", 4)[0]
            }}
          </td>
          <td>
            {{
              item.Spent_items_to_work.type == "machine_for_smeta_work" ||
              item.Spent_items_to_work.type == "machine_for_other_work" ||
              item.Spent_items_to_work.type == "w_product_for_other_work" ||
              item.Spent_items_to_work.type == "w_product_for_smeta_work"
                ? item.Spent_items_to_work.time_spent
                : ""
            }}
          </td>
          <td>{{ item.user }}</td>
          <td>
            <button
              class="btn btn-sm btn-danger m-1"
              @click="removeWorkExpense(item?.Spent_items_to_work?.id)"
            >
              <i class="fas fa-xmark"></i>
            </button>
            <button
              class="btn btn-primary btn-sm m-1"
              data-mdb-target="#payment_history2"
              data-mdb-toggle="modal"
              @click="
                payment_history2.work_id = item?.Spent_items_to_work.id;
                payment_history2.type =
                  type == 'machine_for_other_work'
                    ? 'machine_for_other_work_expense'
                    : 'other_work_expense';
                get_payment_history_id();
              "
              v-if="type !== 'w_product_for_other_work'"
            >
              <i class="fa-solid fa-circle-info"></i>
            </button>
            <button
              class="btn btn-success btn-sm m-1"
              v-if="type !== 'w_product_for_other_work'"
              data-mdb-target="#money_pay"
              data-mdb-toggle="modal"
              @click="
                money_data.to_ = item.Spent_items_to_work.id;
                money_data.for_ = item.Spent_items_to_work.worker_id;
                money_data.sum =
                  item.Spent_items_to_work.price *
                    (item.Spent_items_to_work.type ==
                      'machine_for_smeta_work' ||
                    item.Spent_items_to_work.type == 'machine_for_other_work'
                      ? item.Spent_items_to_work.time_spent
                      : item.Spent_items_to_work.quantity) -
                  item.sum_expense;
                money_data.balance = item.worker_balance;
              "
            >
              <i class="fas fa-money-check"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Data_notfound v-if="info == ''" />

  <nav aria-label="Page navigation example" class="mt-2" v-if="info != ''">
    <ul class="pagination pagination-sm justify-content-end">
      <li>
        <Select_data :id="'building_info'" />
      </li>
      <li class="page-item">
        <select
          class="form-control form-control-sm mx-2 form-select"
          v-model="limit"
        >
          <option value="25" selected>25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </li>
      <li class="page-item">
        <button
          type="button"
          class="page-link"
          @click="get_work_info(work_id, worker_id, type, user_id, page, limit)"
        >
          <i class="fa-solid fa-rotate"></i>
        </button>
      </li>
      <li class="page-item">
        <button class="page-link" @click="page--" :disabled="page == 0">
          <i class="fa-solid fa-angle-left text-dark"></i>
        </button>
      </li>
      <li class="page-item">
        <select class="form-control form-control-sm page_select" v-model="page">
          <option :value="item - 1" v-for="item in pages" :key="item">
            {{ item }}
          </option>
        </select>
      </li>
      <li class="page-item">
        <button class="page-link" @click="page++" :disabled="page == pages - 1">
          <i class="fa-solid fa-angle-right text-dark"></i>
        </button>
      </li>
    </ul>
  </nav>
  <!-- payment history modal -->
  <div
    class="modal fade"
    id="payment_history2"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Тўловлар тарихи</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-end">
            <select
              class="form-control form-control-xl"
              v-model="payment_history2.type"
              @change="get_payment_history_id()"
            >
              <option
                :value="
                  type == 'machine_for_other_work'
                    ? 'machine_for_other_work_expense'
                    : 'other_work_expense'
                "
              >
                Прорап
              </option>
              <option
                :value="
                  type == 'machine_for_other_work'
                    ? 'machine_for_other_work_from_worker_expense'
                    : 'other_work_from_worker_expense'
                "
              >
                Уста
              </option>
            </select>
          </div>
          <div class="table-responsive mt-3 m-1">
            <table class="table table-sm table-hover">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Сумма</th>
                  <th>Ходим</th>
                  <th>Сана</th>
                  <th>Ҳақида</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, inc) in payment_history2.data" :key="item">
                  <td>{{ inc + 1 }}</td>
                  <td>
                    {{ new Intl.NumberFormat().format(item.Expenses.money) }}
                    сўм
                  </td>
                  <td>{{ item.user }}</td>
                  <td>
                    {{ item.Expenses.time.replace("T", " ") }}
                  </td>
                  <td>
                    {{ item.Expenses.comment }}
                  </td>
                  <td>
                    <button
                      class="btn btn-danger btn-sm m-1"
                      @click="removePay(item.Expenses.id)"
                    >
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <Data_notfound v-if="payment_history2.data == ''" />
          </div>
          <nav
            aria-label="Page navigation example"
            class="mt-1"
            v-if="payment_history2.data != ''"
          >
            <ul class="pagination pagination-sm justify-content-end">
              <li class="page-item">
                <select
                  class="form-control form-control-sm form-select mx-1"
                  v-model="payment_history2.limit"
                  @change="get_payment_history_id()"
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </li>
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  @click="get_payment_history_id()"
                >
                  <i class="fa-solid fa-rotate"></i>
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="
                    payment_history2.page--;
                    get_payment_history_id();
                  "
                  :disabled="payment_history2.page == 0"
                >
                  <i class="fa-solid fa-angle-left text-dark"></i>
                </button>
              </li>
              <li class="page-item">
                <select
                  class="form-control form-control-sm page_select"
                  v-model="payment_history2.page"
                  @change="get_payment_history_id()"
                >
                  <option
                    :value="item - 1"
                    v-for="item in payment_history2.pages"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="
                    payment_history2.page++;
                    get_payment_history_id();
                  "
                  :disabled="
                    payment_history2.page == payment_history2.pages - 1
                  "
                >
                  <i class="fa-solid fa-angle-right text-dark"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- pay money -->
  <div class="modal fade" id="money_pay">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Пул тўлаш</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="row px-2">
          <h5 class="text-center">
            Jami: {{ new Intl.NumberFormat().format(money_data.sum) }} сўм
          </h5>
        </div>

        <form @submit.prevent="PayForWork()">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <select
                  class="form-control form-control-sm"
                  v-model="money_data.type"
                >
                  <option
                    :value="
                      type == 'machine_for_other_work'
                        ? 'machine_for_other_work'
                        : 'other_work'
                    "
                  >
                    Прораб
                  </option>
                  <option
                    :value="
                      type == 'machine_for_other_work'
                        ? 'machine_for_other_work_from_worker'
                        : 'other_work_from_worker'
                    "
                  >
                    Уста {{ money_data.balance }} сўм
                  </option>
                </select>
              </div>
              <div class="col-10 mx-auto">
                <label for="sum"> Сумма: </label>
                <input
                  type="number"
                  id="sum"
                  class="form-control form-control-sm"
                  v-model="money_data.money"
                  required
                  placeholder="Суммани киритинг..."
                />
              </div>
              <div class="col-10 mx-auto">
                <label for="izoh"> Изоҳ: </label>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    id="izoh"
                    class="form-control form-control-sm"
                    required
                    v-model="money_data.comment"
                    placeholder="Изоҳ киритинг..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="worker_disabled"
              :disabled="money_data.money === ''"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
              aria-label="Close"
              id="close_pay"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Header_item from "@/components/header/header_item.vue";
import Data_notfound from "@/utils/notfound/data_notfound.vue";
import {
  get_spent_items_to_work,
  get_spent_products_to_work,
  get_work_expenses,
  pay_for_work,
  remove_spented_items,
  take_back_money_from_work,
  workers_get,
} from "@/utils/request/request";
import { catchError, success } from "@/utils/request/handle_api_err";
import Format_numbers from "@/utils/format/format_numbers.vue";
import Select_data from "@/utils/select_data/select_data.vue";

export default {
  name: "Work_Info",
  components: { Header_item, Data_notfound, Format_numbers, Select_data },
  data() {
    return {
      work_id: this.$route.query.id || 0,
      building_id: this.$route.query.building_id || 0,
      worker_id: 0,
      type: this.$route.query.type || "w_product_for_other_work",
      user_id: 0,
      page: 0,
      limit: 25,
      pages: null,
      info: [],
      money_data: {
        money: "",
        from_: 0,
        to_: 0,
        for_: 0,
        comment: "",
        type: "other_work",
        sum: 0,
        balance: 0,
      },
      payType: true,
      payment_history2: {
        limit: 25,
        page: 0,
        type: "other_work_expense",
        pages: null,
        data: [],
        work_id: null,
      },
      work_data: {
        building_id: this.$route.query.id,
        search: "",
        page: 0,
        pages: 1,
        limit: 25,
        name: "Усталар",
        data: {},
      },
    };
  },
  watch: {
    limit(e) {
      this.page = 0;
      this.get_work_info(
        this.work_id,
        this.worker_id,
        this.type,
        this.user_id,
        this.page,
        e
      );
    },
    page(e) {
      this.get_work_info(
        this.work_id,
        this.worker_id,
        this.type,
        this.user_id,
        e,
        this.limit
      );
    },
    type(e) {
      this.page = 0;
      this.get_work_info(
        this.work_id,
        this.worker_id,
        e,
        this.user_id,
        this.page,
        this.limit
      );
    },
  },
  methods: {
    get_work_info(work_id, worker_id, type, user_id, page, limit) {
      this.$store.dispatch("setLoading", { isLoading: true });

      this.info = [];
      this.pages = null;

      if (type !== "w_product_for_other_work")
        get_spent_items_to_work(
          work_id,
          worker_id,
          type,
          user_id,
          page,
          limit,
          "",
          "",
          this.building_id
        )
          .then((res) => {
            this.info = res.data.data;
            this.pages = res.data.pages;
            this.$store.dispatch("setLoading", { isLoading: false });
          })
          .catch((err) => {
            catchError(err);
            this.$store.dispatch("setLoading", { isLoading: false });
          });
      else
        get_spent_products_to_work(
          work_id,
          type,
          user_id,
          "",
          this.building_id,
          page,
          limit
        )
          .then((res) => {
            this.info = res.data.data;
            this.pages = res.data.pages;
            this.$store.dispatch("setLoading", { isLoading: false });
          })
          .catch((err) => {
            catchError(err);
            this.$store.dispatch("setLoading", { isLoading: false });
          });
    },
    removeWorkExpense(id) {
      remove_spented_items(id).then((res) => {
        success(res);
        this.get_work_info(
          this.work_id,
          this.worker_id,
          this.type,
          this.user_id,
          this.page,
          this.limit
        );
      });
    },
    removePay(id) {
      take_back_money_from_work(id).then((res) => {
        success(res);
        this.get_payment_history_id();
      });
    },
    PayForWork() {
      this.$store.dispatch("setLoading", { isLoading: true });
      let arr = [];
      this.money_data.from_ =
        this.money_data.type == "other_work" ? 0 : this.money_data.for_;
      arr.push(this.money_data);
      pay_for_work(this.money_data.type, arr)
        .then((res) => {
          success(res);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("close_pay").click();
          this.get_work_info(
            this.work_id,
            this.worker_id,
            this.type,
            this.user_id,
            this.page,
            this.limit
          );
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    get_payment_history_id() {
      this.$store.dispatch("setLoading", { isLoading: true });
      get_work_expenses(
        this.payment_history2.work_id,
        this.payment_history2.type,
        0,
        this.payment_history2.page,
        this.payment_history2.limit
      )
        .then((res) => {
          this.payment_history2.data = res.data.data;
          this.payment_history2.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    get_works(page, limit) {
      workers_get(page, limit, this.work_data.search, this.building_id)
        .then((res) => {
          this.work_data.data = res.data;
          this.work_data.pages = res.data.pages;
        })
        .catch((err) => {
          catchError(err);
        });
    },
  },
  created() {
    this.get_work_info(
      this.work_id,
      this.worker_id,
      this.type,
      this.user_id,
      this.page,
      this.limit
    );
  },
};
</script>

<style lang="scss" scoped>
table {
  min-width: 1200px;
  width: 100%;
}

.table-responsive {
  max-height: 75vh;
}

@media (max-width: 500px) {
  .table-responsive {
    max-height: 110vh;
  }
}
</style>
