<template>
  <Header_item />

  <ul class="nav nav-tabs nav-fill nav-justified mb-3 row">
    <li class="nav-item col-lg-4 col-md-6 col-sm-12 my-1">
      <a
        class="nav-link"
        type="button"
        @click="
          content = 'expense';
          fixed_history_get();
        "
        :class="content == 'expense' ? 'active' : ''"
      >
        Чиқимлар
      </a>
    </li>
    <li class="nav-item col-lg-4 col-md-6 col-sm-12 my-1">
      <a
        class="nav-link"
        type="button"
        @click="
          content = 'work';
          work_expenses_get();
        "
        :class="content == 'work' ? 'active' : ''"
      >
        Иш учун толанган толовлар
      </a>
    </li>
    <li class="nav-item col-lg-4 col-md-6 col-sm-12 my-1">
      <a
        class="nav-link"
        type="button"
        @click="
          content = 'master';
          worker_expenses_get();
        "
        :class="content == 'master' ? 'active' : ''"
      >
        Усталарга берилган аванс
      </a>
    </li>
  </ul>
  <div v-if="content == 'expense'">
    <form
      @submit.prevent="
        h_page = 0;
        fixed_history_get();
      "
      class="mb-2"
    >
      <div class="row">
        <div class="col">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="h_from_time"
            required
          />
        </div>
        <div class="col">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="h_to_time"
            required
          />
        </div>
        <div class="col">
          <div class="btn-group w-100">
            <button
              class="btn btn-outline-primary btn-sm w-80"
              :disabled="h_from_time == '' || h_to_time == ''"
            >
              <i class="fa-solid fa-search"></i>
            </button>
            <button
              class="btn btn-outline-danger btn-sm w-20"
              @click="
                h_from_time = '';
                h_to_time = '';
                fixed_history_get();
              "
              v-if="h_from_time && h_to_time"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
    <Fixed :fixed_history="fixed_history" @to="sort_to" />

    <nav
      aria-label="Page navigation example"
      class="mt-1"
      v-if="fixed_history != ''"
    >
      <ul class="pagination pagination-sm justify-content-end">
        <li>
          <Select_data :id="'select_fixed_expense_data'" />
        </li>
        <li class="page-item">
          <select
            class="form-control form-control-sm form-select mx-1"
            v-model="h_limit"
            @change="
              h_page = 0;
              fixed_history_get();
            "
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </li>
        <li class="page-item">
          <button type="button" class="page-link" @click="fixed_history_get()">
            <i class="fa-solid fa-rotate"></i>
          </button>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            @click="
              h_page--;
              fixed_history_get();
            "
            :disabled="h_page == 0"
          >
            <i class="fa-solid fa-angle-left text-dark"></i>
          </button>
        </li>
        <li class="page-item">
          <select
            class="form-control form-control-sm page_select"
            v-model="h_page"
            @change="fixed_history_get()"
          >
            <option :value="item - 1" v-for="item in h_pages" :key="item">
              {{ item }}
            </option>
          </select>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            @click="
              h_page++;
              fixed_history_get();
            "
            :disabled="h_page == h_pages - 1"
          >
            <i class="fa-solid fa-angle-right text-dark"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
  <div v-else-if="content == 'work'">
    <div
      class="table-responsive m-1"
      style="min-height: 68vh; max-height: 68vh"
    >
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th style="width: 10%">№</th>
            <th style="width: 15%">Сумма</th>
            <th style="width: 15%">Сана</th>
            <th style="width: 15%">Изох</th>
            <th style="width: 15%">Ходим</th>
            <th style="width: 15%">Ишчи</th>
            <th style="width: 15%">Иш номи</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in work.data" :key="item">
            <td>{{ i + 1 }}</td>
            <td>
              {{ new Intl.NumberFormat().format(item.Expenses.money) }} сўм
            </td>
            <td>
              {{
                new Date(item.Expenses.time)
                  .toLocaleDateString({
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                  .slice(0, 10)
              }}
            </td>
            <td>{{ item.Expenses.comment }}</td>
            <td>{{ item.user }}</td>
            <td>{{ item.worker }}</td>
            <td>{{ item.work_name }}</td>
          </tr>
        </tbody>
      </table>
      <Data_notfound v-if="!work.data.length" />
    </div>
    <nav
      aria-label="Page navigation example"
      class="mt-1"
      v-if="work.data.length"
    >
      <ul class="pagination pagination-sm justify-content-end">
        <li class="page-item">
          <select
            class="form-control form-control-sm form-select mx-1"
            v-model="work.limit"
            @change="
              work.page = 0;
              work_expenses_get();
            "
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </li>
        <li class="page-item">
          <button type="button" class="page-link" @click="work_expenses_get()">
            <i class="fa-solid fa-rotate"></i>
          </button>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            @click="
              work.page--;
              work_expenses_get();
            "
            :disabled="work.page == 0"
          >
            <i class="fa-solid fa-angle-left text-dark"></i>
          </button>
        </li>
        <li class="page-item">
          <select
            class="form-control form-control-sm page_select"
            v-model="work.page"
            @change="work_expenses_get()"
          >
            <option :value="item - 1" v-for="item in work.pages" :key="item">
              {{ item }}
            </option>
          </select>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            @click="
              work.page++;
              work_expenses_get();
            "
            :disabled="work.page == work.pages - 1"
          >
            <i class="fa-solid fa-angle-right text-dark"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
  <div v-else>
    <div
      class="table-responsive m-1"
      style="min-height: 68vh; max-height: 68vh"
    >
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th style="width: 10%">№</th>
            <th style="width: 18%">Сумма</th>
            <th style="width: 18%">Сана</th>
            <th style="width: 18%">Изох</th>
            <th style="width: 18%">Ходим</th>
            <th style="width: 18%">Ишчи</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in worker.data" :key="item">
            <td>{{ i + 1 }}</td>
            <td>
              {{ new Intl.NumberFormat().format(item.Expenses.money) }} сўм
            </td>
            <td>
              {{
                new Date(item.Expenses.time)
                  .toLocaleDateString({
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                  .slice(0, 10)
              }}
            </td>
            <td>{{ item.Expenses.comment }}</td>
            <td>{{ item.user }}</td>
            <td>{{ item.worker }}</td>
          </tr>
        </tbody>
      </table>
      <Data_notfound v-if="!worker.data.length" />
    </div>
    <nav
      aria-label="Page navigation example"
      class="mt-1"
      v-if="worker.data.length"
    >
      <ul class="pagination pagination-sm justify-content-end">
        <li class="page-item">
          <select
            class="form-control form-control-sm form-select mx-1"
            v-model="worker.limit"
            @change="
              worker.page = 0;
              worker_expenses_get();
            "
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </li>
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            @click="worker_expenses_get()"
          >
            <i class="fa-solid fa-rotate"></i>
          </button>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            @click="
              worker.page--;
              worker_expenses_get();
            "
            :disabled="worker.page == 0"
          >
            <i class="fa-solid fa-angle-left text-dark"></i>
          </button>
        </li>
        <li class="page-item">
          <select
            class="form-control form-control-sm page_select"
            v-model="worker.page"
            @change="worker_expenses_get()"
          >
            <option :value="item - 1" v-for="item in worker.pages" :key="item">
              {{ item }}
            </option>
          </select>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            @click="
              worker.page++;
              worker_expenses_get();
            "
            :disabled="worker.page == worker.pages - 1"
          >
            <i class="fa-solid fa-angle-right text-dark"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Fixed from "@/components/expences/Fixed.vue";
import Header_item from "@/components/header/header_item.vue";
import Select_data from "@/utils/select_data/select_data.vue";
import {
  get_fixed_history,
  get_worker_expenses,
  get_work_expenses,
} from "@/utils/request/request";
import { catchError } from "@/utils/request/handle_api_err";
import Data_notfound from "@/utils/notfound/data_notfound.vue";

export default {
  name: "expense-history-admin",
  components: { Header_item, Select_data, Fixed, Data_notfound },
  data() {
    return {
      content: "expense",
      fixed_history: [],
      h_limit: 25,
      h_page: 0,
      h_pages: null,
      h_from: this.$route.query.id,
      h_from_time: "",
      h_to_time: "",
      h_to: 0,
      h_user_id: 0,
      work: {
        data: [],
        to: 0,
        type: "",
        building_id: this.$route.query.id,
        page: 0,
        pages: 0,
        limit: 25,
      },
      worker: {
        data: [],
        to: 0,
        building_id: this.$route.query.id,
        page: 0,
        pages: 0,
        limit: 25,
      },
    };
  },
  methods: {
    fixed_history_get() {
      this.$store.dispatch("setLoading", { isLoading: true });
      get_fixed_history(
        this.h_page,
        this.h_limit,
        this.h_from,
        this.h_to,
        this.h_user_id,
        this.h_from_time,
        this.h_to_time
      )
        .then((res) => {
          this.fixed_history = res.data.data;
          this.h_pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    work_expenses_get() {
      this.$store.dispatch("setLoading", { isLoading: true });
      get_work_expenses(
        0,
        "",
        this.work.building_id,
        this.work.page,
        this.work.limit
      )
        .then((res) => {
          this.work.data = res.data.data;
          this.work.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    worker_expenses_get() {
      this.$store.dispatch("setLoading", { isLoading: true });
      get_worker_expenses(
        0,
        this.worker.building_id,
        this.worker.page,
        this.worker.limit
      )
        .then((res) => {
          this.worker.data = res.data.data;
          this.worker.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    sort_to(e) {
      this.h_page = 0;
      this.h_to = e;
      this.fixed_history_get();
    },
  },
  created() {
    this.fixed_history_get();
  },
};
</script>
