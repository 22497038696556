<template>
  <div>
    <Header_item />
    <div class="row d-flex justify-content-between">
      <div class="col-3 mt-1 d-flex align-items-center">
        <span>Лимит</span>
        <select
          class="form-control form-control-sm mx-2 form-select"
          v-model="limit"
          @change="get_product()"
        >
          <option value="25" selected>25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div class="col-3 mt-2 d-flex align-items-center">
        <select
          class="form-control form-control-sm mx-2 form-select"
          v-model="user_id"
          @change="get_product()"
        >
          <option :value="current_user" selected>Барчаси</option>
          <option
            v-for="item in foreman"
            :key="item.user_id"
            :value="item.user_id"
          >
            {{ item.user }}
          </option>
        </select>
      </div>
      <div class="col-3 mt-2 d-flex align-items-center">
        <select
          class="form-control form-control-sm mx-2 form-select"
          v-model="status"
          @change="get_product()"
        >
          <option value="" selected>Барчаси</option>
          <option value="adminda_proche">Админда</option>
          <option value="zakupshikda_proche">Закупшикда</option>
          <option value="taminotchida_proche">Таъминотчида</option>
          <option value="taminotchida_proche_qabul_qilindi">
            Қабул қилинди
          </option>
          <option value="rejected_proche">Рухсат берилмган</option>
        </select>
      </div>
      <div class="col-3 mt-2 d-flex align-items-center justify-content-end">
        <span>Қидириш: </span>
        <div class="input-group input-group-sm mx-2">
          <input
            type="search"
            v-model="search"
            @input="get_product()"
            class="form-control form-control-sm"
          />
          <button
            type="button"
            class="btn btn-outline-secondary me-2 ms-2"
            data-mdb-toggle="modal"
            data-mdb-target="#exampleModal"
          >
            <i class="fa-solid fa-user-plus"></i>
          </button>
          <input
            class="d-none"
            type="file"
            id="file"
            @change="post_file($event)"
          />
          <label for="file">
            <div class="btn btn-primary btn-md" type="button">
              <i class="fa-solid fa-cloud-arrow-up"></i>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="row row_building" v-if="role == 'prorap'">
      <div
        class="col-md-4 col-sm-6 col-lg-4 col-xxl-4 my-1"
        v-for="(item, inc) in product.data"
        :key="item"
      >
        <div class="card h-100 dark">
          <div class="card-body text-center">
            <span class="float-start badge bg-primary">{{ inc + 1 }}</span>
            <h5 class="card-title text-center mb-0">
              {{ item.building }}
            </h5>
            <hr class="mt-2 mb-2 dark" />
            <h6>
              <b>Номи: {{ item.Proche_sorovlar.name }}</b>
            </h6>
            <h6>
              <b>Санаси: {{ item.Proche_sorovlar.time.replace("T", " ") }}</b>
            </h6>
            <h6>
              <b
                >Миқдори: {{ item.Proche_sorovlar.quantity }}
                {{ item.Proche_sorovlar.olchov_birligi }}</b
              >
            </h6>
            <h6>
              <b> {{ item.Proche_sorovlar.comment }}</b>
            </h6>

            <span
              v-if="item.Proche_sorovlar.status == 'adminda_proche'"
              class="badge rounded-pill bg-danger"
              >Админ тасдиқлаш кутилмоқда</span
            >
            <span
              v-else-if="item.Proche_sorovlar.status == 'zakupshikda_proche'"
              class="badge rounded-pill bg-warning"
              >Закупшик тасдиқлаш кутилмоқда</span
            >
            <span
              v-else-if="item.Proche_sorovlar.status == 'taminotchida_proche'"
              class="badge rounded-pill bg-[primary]"
              >Таминотчи тасдиқлаш кутилмоқда</span
            >
            <hr class="mt-2 mb-2 dark" />
            <div class="coll mx-1" v-if="role != 'prorap'">
              <button
                class="btn btn-sm btn-danger btn-active-bg-green"
                data-mdb-toggle="modal"
                data-mdb-target="#exampleDelete"
                @click="item_id = item"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav
      aria-label="Page navigation example"
      class="mt-2"
      v-if="buildings != ''"
    >
      <ul class="pagination pagination-sm justify-content-end">
        <li class="page-item">
          <button type="button" class="page-link" @click="get_product()">
            <i class="fa-solid fa-rotate"></i>
          </button>
        </li>
        <li class="page-item">
          <button class="page-link" @click="page--" :disabled="page == 0">
            <i class="fa-solid fa-angle-left text-dark"></i>
          </button>
        </li>
        <li class="page-item">
          <select
            class="form-control form-control-sm page_select"
            v-model="page"
          >
            <option :value="item - 1" v-for="item in product.pages" :key="item">
              {{ item }}
            </option>
          </select>
        </li>
        <li class="page-item">
          <button
            class="page-link"
            @click="page++"
            :disabled="page == product.pages - 1"
          >
            <i class="fa-solid fa-angle-right text-dark"></i>
          </button>
        </li>
      </ul>
    </nav>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Янги сўров қўшиш
            </h1>
            <button
              type="button"
              class="btn-close"
              data-mdb-dismiss="modal"
              aria-label="Close"
              id="close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              id="form"
              @submit.prevent="post_product"
              v-if="role == 'prorap'"
            >
              <div class="row">
                <div class="col-12 mb-3">
                  <label class="" for="name">Номи</label>
                  <input
                    class="form-control"
                    required
                    type="text"
                    id="name"
                    v-model="product_post.name"
                    placeholder="Номини киритинг..."
                  />
                </div>
                <div class="col-12 mb-3">
                  <label class="" for="hajm">Ҳажми</label>
                  <input
                    class="form-control"
                    required
                    type="text"
                    id="hajm"
                    v-model="product_post.quantity"
                    placeholder="Ҳажмини киритинг..."
                  />
                </div>
                <div class="col-12 mb-3">
                  <label class="" for="olchov">Ўлчов бирлиги</label>
                  <input
                    class="form-control"
                    required
                    type="text"
                    id="olchov"
                    v-model="product_post.olchov_birligi"
                    placeholder="Ўлчов бирлигини киритинг..."
                  />
                </div>
                <div class="col-12 mb-3">
                  <label class="" for="izoh">Изоҳ</label>
                  <input
                    class="form-control"
                    required
                    type="text"
                    id="izoh"
                    v-model="product_post.comment"
                    placeholder="Изоҳ киритинг..."
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-mdb-dismiss="modal"
              id="close"
            >
              Ёпиш
            </button>
            <button type="submit" form="form" class="btn btn-primary">
              Қўшиш
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleDelete"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Маълумот ўчириш !
            </h1>
            <button
              type="button"
              class="btn-close"
              data-mdb-dismiss="modal"
              aria-label="Close"
              id="closeDelete"
            ></button>
          </div>
          <div class="modal-body">Видеони ўчирмоқчимисиз ?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-mdb-dismiss="modal"
              id="item_close"
            >
              Йўқ
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="building_d_remove()"
            >
              Ҳа
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header_item from "@/components/header/header_item.vue";
import {
  proche_s_get,
  product_add,
  proche_s_put,
  foreman_get,
  create_proche_sorov,
} from "@/utils/request/request";
import { catchError, success } from "@/utils/request/handle_api_err";
export default {
  data() {
    return {
      role: localStorage.getItem("role"),
      building_id: this.$route.query.id,
      user_id: 0,
      page: 0,
      limit: 25,
      status: "",
      search: "",
      product: [],
      item_id: {},
      foreman: {},
      product_post: {
        id: 0,
        name: "",
        quantity: null,
        olchov_birligi: "",
        comment: "",
        building_id: this.$route.query.id,
      },
    };
  },
  components: { Header_item },
  methods: {
    get_product() {
      this.$store.dispatch("setLoading", { isLoading: true });
      proche_s_get(
        this.page,
        this.limit,
        this.building_id,
        this.status,
        this.user_id,
        this.search
      )
        .then((res) => {
          this.product = res.data;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          catchError(err);
        });
    },
    post_product() {
      this.$store.dispatch("setLoading", { isLoading: true });
      product_add(this.user_id, this.product_post)
        .then((res) => {
          success(res);
          document.getElementById("close").click();
          this.$store.dispatch("setLoading", { isLoading: false });
          this.get_product();
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    building_d_remove() {
      this.$store.dispatch("setLoading", { isLoading: true });
      let item = {
        id: this.item_id.Proche_sorovlar.id,
        name: this.item_id.Proche_sorovlar.name,
        quantity: this.item_id.Proche_sorovlar.quantity,
        olchov_birligi: this.item_id.Proche_sorovlar.olchov_birligi,
        comment: this.item_id.Proche_sorovlar.comment,
        building_id: this.item_id.Proche_sorovlar.building_id,
      };
      proche_s_put(false, this.item_id.Proche_sorovlar.taminotchi_id, [item])
        .then((res) => {
          success(res);
          document.getElementById("item_close").click();
          this.get_product();
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          catchError(err);
        });
    },
    foremans_get() {
      this.$store.dispatch("setLoading", { isLoading: true });
      foreman_get(this.building_id, this.user_id)
        .then((res) => {
          this.foreman = res.data;
          console.log(res.data);
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          catchError(err);
        });
    },
    post_file(event) {
      console.log(event.target.files[0]);
      let Data = new FormData();
      Data.append("file", event.target.files[0]);
      create_proche_sorov(Data, this.building_id)
        .then((res) => {
          success(res);
        })
        .catch((err) => {
          catchError(err);
        });
    },
  },
  created() {
    this.get_product();
    this.foremans_get();
  },
  computed: {
    current_user() {
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  min-width: 1000px;
  width: 100%;
}

.table-responsive {
  max-height: 66vh;
}

div.row_building {
  max-height: 68vh;
  overflow: auto;
}

@media (max-width: 500px) {
  .table-responsive {
    max-height: 85vh;
  }
}

.modal-content {
  border: none;
}

.descinline {
  display: inline;
}

.descempty {
  display: none;
}

hr {
  border: none;
  opacity: 1;
  border-radius: 50px;
}

hr.success {
  background: green;
  animation: success 0.8s infinite alternate linear;
}

hr.dark {
  background: black;
  animation: darker 0.8s infinite alternate linear;
}

hr.danger {
  background: red;
  animation: danger 0.8s infinite alternate linear;
}

hr.warning {
  background: #ffc107;
  animation: warning 0.8s infinite alternate linear;
}

.card.success {
  border: 1px solid green;
  animation: success 0.8s infinite alternate linear;
}

.card.dark {
  border: 1px solid black;
  animation: darker 0.8s infinite alternate linear;
}

.card.danger {
  border: 1px solid red;
  animation: danger 0.8s infinite alternate linear;
}

.card.warning {
  border: 1px solid #ffc107;
  animation: warning 0.8s infinite alternate linear;
}

@keyframes success {
  0% {
    box-shadow: 0 0 0 1.8px rgb(0, 128, 0, 0.4);
  }
}
@keyframes danger {
  0% {
    box-shadow: 0 0 0 1.8px rgb(255, 0, 0, 0.4);
  }
}
@keyframes warning {
  0% {
    box-shadow: 0 0 0 1.8px rgb(255, 193, 7, 0.4);
  }
}

@keyframes darker {
  0% {
    box-shadow: 0 0 0 1.8px rgb(0, 0, 0, 0.4);
  }
}

.zoom .coll:hover {
  transform: scale(1.1) translateY(-2px);
}

.zoom .coll {
  margin: 5px 0px;
  transition: all 0.1s linear;
}
.d-flex.justify-content-center.zoom {
  zoom: 92%;
  flex-wrap: wrap;
}
</style>
