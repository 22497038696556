<template>
  <div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="seriesData"
      height="650"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "diagramma-works",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "line-chart",
          height: "00px",
        },
        xaxis: {
          labels: {
            show: false,
          },
          categories: [],
        },
      },
      seriesData: [
        {
          name: "Bajarilishi kerak",
          data: [],
        },
        {
          name: "Bajarildi",
          data: [],
        },
      ],
    };
  },
  methods: {
    start(data) {
      this.chartOptions.xaxis.categories = [];
      this.seriesData[0].data = [];
      this.seriesData[1].data = [];
      data.forEach((element) => {
        this.chartOptions.xaxis.categories.push(element.Other_works.name);
        this.seriesData[0].data.push(element.Other_works.quantity);
        this.seriesData[1].data.push(element.completed_quantity);
      });
    },
  },
};
</script>
