<template>
  <Header_item :name="$route.query.name" />

  <div class="row">
    <form @submit.prevent="get()">
      <div class="col-12 col-sm-12 col-md-8 mx-auto">
        <div class="row">
          <div class="col-12 col-md my-1">
            <input
              type="date"
              class="form-control"
              v-model="date.from"
              required
            />
          </div>
          <div class="col-12 col-md my-1">
            <input
              type="date"
              class="form-control"
              v-model="date.to"
              required
            />
          </div>
          <div class="col-12 col-md-auto my-1">
            <button class="btn btn-primary w-100">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row" v-for="item in data" :key="item">
    <div class="col-12">
      <h3 class="text-center mb-0 text-primary fw-bold">
        {{
          new Date(item.day)
            .toLocaleDateString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
            .slice(0, 10)
        }}
      </h3>
      <hr class="my-2" />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Йукланган видеолар</h4>
      <div class="row">
        <div class="col-md-4" v-for="video in item.uploaded_file" :key="video">
          <video style="width: 100%" height="240" controls>
            <source :src="url + video.file" type="video/mp4" />
          </video>
        </div>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-2">Прораплар ёзган изоҳлар</h4>
      <div class="row">
        <div
          class="col-md-4 col-sm-6 col-lg-4 col-xxl-4 my-1"
          v-for="item in item.uploaded_comment"
          :key="item"
        >
          <div class="card h-100">
            <div class="card-body text-center">
              <h5 class="card-title text-center mb-0">
                <span class="fs-6">
                  {{ item.time.replace("T", " ") }}
                </span>
              </h5>
              <hr class="mt-2 mb-2 dark" />

              <h6>
                <b>{{ item.comment }}</b>
              </h6>
              <hr class="mt-2 mb-2 dark" />
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Иш учун толовлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Иш номи</th>
              <th>Ишчи</th>
              <th>Ходим</th>
              <th>Сумма</th>
              <th>Соат</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(work_expense, i) in item.work_expenses_data"
              :key="work_expense"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ work_expense.work_name }}</td>
              <td>{{ work_expense.worker }}</td>
              <td>{{ work_expense.user }}</td>
              <td>{{ work_expense.Expenses?.money }} сум</td>
              <td>{{ work_expense.Expenses?.time.slice(11, 16) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Усталар учун толовлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Уста</th>
              <th>Ходим</th>
              <th>Сумма</th>
              <th>Изох</th>
              <th>Соат</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(worker_expense, i) in item.worker_expenses_data"
              :key="worker_expense"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ worker_expense.worker }}</td>
              <td>{{ worker_expense.user }}</td>
              <td>{{ worker_expense.Expenses?.money }} сум</td>
              <td>{{ worker_expense.Expenses?.comment }}</td>
              <td>{{ worker_expense.Expenses?.time.slice(11, 16) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Таминот учун толовлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Махсулот</th>
              <th>Таминотчи корхона</th>
              <th>Ходим</th>
              <th>Сумма</th>
              <th>Изох</th>
              <th>Соат</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(supply_expense, i) in item.supply_expenses_data"
              :key="supply_expense"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ supply_expense.supply_product }}</td>
              <td>
                {{ supply_expense.Markets?.name }} +998{{
                  supply_expense.Markets?.phone
                }}
              </td>
              <td>{{ supply_expense.user }}</td>
              <td>{{ supply_expense.Expenses?.money }} сум</td>
              <td>{{ supply_expense.Expenses?.comment }}</td>
              <td>{{ supply_expense.Expenses?.time.slice(11, 16) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Доимий чикимлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Чиким тури</th>
              <th>Ходим</th>
              <th>Сумма</th>
              <th>Соат</th>
              <th>Изох</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(fixed, i) in item.fixed_expenses_data" :key="fixed">
              <td>{{ i + 1 }}</td>
              <td>{{ fixed.fixed_expense }}</td>
              <td>{{ fixed.user }}</td>
              <td>
                {{ Intl.NumberFormat().format(fixed.Expenses?.money) }} сум
              </td>
              <td>
                {{ fixed.Expenses?.time.slice(11, 16) }}
              </td>
              <td>{{ fixed.Expenses?.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Бажарилган ишлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Уста</th>
              <th>Иш номи</th>
              <th>Хажм</th>
              <th>Соат</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(work, i) in item.spent_items_to_work_data" :key="work">
              <td>{{ i + 1 }}</td>
              <td>{{ work.worker }}</td>
              <td>{{ work.work.split("=>", 4)[0] }}</td>
              <td>
                {{ work.Spent_items_to_work?.quantity }}
                {{ work.work.split("=>", 4)[1] }}
              </td>
              <td>
                {{ work.Spent_items_to_work?.time.slice(11, 16) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Сарфланган махсулотлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Обйект</th>
              <th>Махсулот</th>
              <th>Иш</th>
              <th>Ходим</th>
              <th>Хажм</th>
              <th>Соат</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(pr_work, i) in item.spent_pr_to_work_data"
              :key="pr_work"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ pr_work.building }}</td>
              <td>{{ pr_work.item.split("//=>//", 4)[0] }}</td>
              <td>{{ pr_work.work.split("=>", 4)[0] }}</td>
              <td>{{ pr_work.user }}</td>
              <td>
                {{ pr_work.Spent_items_to_work?.quantity }}
                {{ pr_work.work.split("=>", 4)[1] }}
              </td>
              <td>
                {{ pr_work.Spent_items_to_work?.time.slice(11, 16) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Таминотлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Обйект</th>
              <th>Махсулот</th>
              <th>Хажм</th>
              <th>Нарх</th>
              <th>Таминотчи</th>
              <th>Омборчи</th>
              <th>Соат</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(supply, i) in item.supplies_data" :key="supply">
              <td>{{ i + 1 }}</td>
              <td>{{ supply.building }}</td>
              <td>{{ supply.product }}</td>
              <td>
                {{ supply.Supplies?.quantity }}
                {{ supply.Supplies?.olchov_birligi }}
              </td>
              <td>{{ supply.Supplies?.price }} сум</td>
              <td>{{ supply.taminotchi }}</td>
              <td>{{ supply.warehouseman }}</td>
              <td>{{ supply.Supplies?.time.slice(11, 16) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
    <div class="col-12">
      <h4 class="text-center mb-0">Махсулот учун соровлар</h4>
      <div class="table-responsive m-1">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Номи</th>
              <th>Хажм</th>
              <th>Нарх</th>
              <th>Соат</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(proche, i) in item.proche_sorovlar" :key="proche">
              <td>{{ i + 1 }}</td>
              <td>{{ proche.Proche_sorovlar.name }}</td>
              <td>
                {{ proche.Proche_sorovlar?.quantity }}
                {{ proche.Proche_sorovlar?.olchov_birligi }}
              </td>
              <td>{{ proche.Supplies?.price }}</td>
              <td>{{ proche.Supplies?.time.slice(11, 16) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import Header_item from "@/components/header/header_item.vue";
import { get_building_daily_info } from "@/utils/request/request";
import { catchError, link } from "@/utils/request/handle_api_err";
export default {
  name: "building-statistic",
  components: { Header_item },
  data() {
    return {
      building_id: this.$route.query.id,
      date: {
        from: new Date().toISOString().slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
      },
      limit: 25,
      page: 0,
      pages: null,
      data: [],
      url: link,
    };
  },
  methods: {
    get() {
      this.$store.dispatch("setLoading", { isLoading: true });

      get_building_daily_info(
        this.page,
        this.limit,
        this.date.from,
        this.date.to,
        this.building_id
      )
        .then((res) => {
          this.data = res.data.data;
          this.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
  },
  created() {
    this.get();
  },
};
</script>
