<template>
  <div
    class="modal fade"
    id="building_document"
    tabindex="-1"
    aria-labelledby="building_documents"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="building_documents">
            {{ name }} ҳужжатлари
          </h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="$emit('document_add', body)"
            v-if="role == 'pto'"
          >
            <div class="row">
              <div class="col">
                <input
                  type="file"
                  id="file"
                  class="form-control form-control-sm"
                  hidden
                  required
                  @change="upload"
                />
                <button
                  class="btn btn-sm btn-outline-primary w-100"
                  type="button"
                  @click="file"
                >
                  <i class="fa-solid fa-file-arrow-up"></i>
                  <span id="txt" class="mx-1">{{ txt }}</span>
                </button>
              </div>
              <div class="col">
                <textarea
                  class="form-control form-control-sm"
                  rows="2"
                  required
                  v-model="body.comment"
                  placeholder="Изох"
                ></textarea>
              </div>
              <div class="col">
                <button
                  class="btn btn-sm btn-outline-success w-100"
                  type="submit"
                  :disabled="!body.file || !body.comment"
                  id="document_button"
                >
                  <i class="fa-solid fa-circle-check"></i>
                </button>
              </div>
            </div>
          </form>

          <div class="table-responsive my-2" v-if="data != ''">
            <table class="table table-sm table-hover">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Файл</th>
                  <th>Изох</th>
                  <th>Сана</th>
                  <th>
                    <select
                      v-model="type"
                      class="form-control"
                      @change="
                        $parent.select_id(
                          $parent.building_id,
                          $parent.building_name,
                          2,
                          type
                        )
                      "
                    >
                      <option value="">барчаси</option>
                      <option value="building_document">
                        Обйект хужжатлари
                      </option>
                      <option value="daily_file">Прораб йуклаган файл</option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, idx) in data" :key="item">
                  <td>{{ idx + 1 }}</td>
                  <td>{{ item.Building_documents.file }}</td>
                  <td>
                    {{ item.Building_documents.comment }}
                  </td>
                  <td>
                    {{
                      new Date(item.Building_documents.time)
                        .toLocaleDateString({
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                        .slice(0, 10)
                    }}
                  </td>
                  <td>
                    <div class="btn-group">
                      <button
                        class="btn btn-sm btn-primary"
                        @click="download(item)"
                      >
                        <i class="fa-solid fa-download"></i>
                      </button>
                      <a
                        target="_blank"
                        :href="`${url}${item.Building_documents.file}`"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fa-solid fa-eye"></i>
                      </a>
                      <button
                        v-if="role == 'pto'"
                        class="btn btn-sm btn-danger"
                        @click="$emit('remove', item.Building_documents.id)"
                        id="remove_document"
                      >
                        <i class="fa-solid fa-circle-xmark"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { link } from "@/utils/request/handle_api_err";
import axios from "axios";
export default {
  name: "BuildingDocuments",
  props: ["id", "name", "data"],
  data() {
    return {
      body: {
        file: "",
        comment: "",
        type: "building_document",
      },
      txt: "файл юклаш",
      role: localStorage.getItem("role"),
      type: "",
      url: link,
    };
  },
  methods: {
    file() {
      document.getElementById("file").click();
    },
    upload(e) {
      this.body.file = e.target.files[0];
      this.txt = e.target.files[0].name.slice(0, 20);
    },

    download(e) {
      const fileUrl = this.url + e.Building_documents.file; // Replace with the actual URL of the file
      axios({
        url: fileUrl,
        method: "GET",
        responseType: "blob", // Set the response type to blob
      })
        .then((response) => {
          // Create a temporary URL for the downloaded file
          const url = window.URL.createObjectURL(new Blob([response.data]));

          // Create an anchor element
          const link = document.createElement("a");
          link.href = url;

          // Set the filename for the downloaded file
          link.setAttribute("download", "filename.ext");

          // Add the crossorigin attribute to handle CORS
          link.setAttribute("crossorigin", "");

          // Append the anchor element to the DOM and trigger the click event to start the download
          document.body.appendChild(link);
          link.click();

          // Clean up by removing the anchor element and revoking the temporary URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-responsive {
  max-height: 70vh;
}

@media (max-width: 500px) {
  .table-responsive {
    min-height: 90vh;
  }
}
</style>
