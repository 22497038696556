<template>
  <Header_item />

  <div class="row">
    <div class="col">
      <input
        type="date"
        class="form-control form-control-sm"
        v-model="from_time"
      />
    </div>
    <div class="col">
      <input
        type="date"
        class="form-control form-control-sm"
        v-model="to_time"
      />
    </div>
    <div class="col">
      <select
        class="form-control form-control-sm"
        v-model="status"
        @change="get_talabnoma()"
      >
        <option value="true">тасдикланган</option>
        <option value="false">тасдикланмаган</option>
      </select>
    </div>
    <div class="col">
      <div class="btn-group w-100">
        <button
          class="btn btn-outline-primary btn-sm w-80"
          :disabled="!from_time || !to_time"
          @click="get_talabnoma()"
        >
          <i class="fa-solid fa-search"></i>
        </button>
        <button
          class="btn btn-outline-danger btn-sm w-20"
          @click="
            from_time = '';
            to_time = '';
          "
          v-if="from_time && to_time"
        >
          <i class="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="row my-2">
    <div class="col d-flex align-items-center justify-content-center">
      <button
        class="btn btn-sm btn-secondary"
        data-mdb-toggle="modal"
        data-mdb-target="#create_talabnoma"
        v-if="role == 'taminotchi'"
      >
        Талабнома <i class="fa-solid fa-circle-plus"></i>
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <td>№</td>
          <td>Ходим</td>
          <td>Тасдиклаган ходим</td>
          <td>Объект</td>
          <td>Сана</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in talabnoma.data" :key="item">
          <td>{{ item.Talabnomalar.id }}</td>
          <td>{{ item.user }}</td>
          <td>{{ item.confirmed_user }}</td>
          <td>{{ item.building }}</td>
          <td>
            {{
              new Date(item.Talabnomalar.time)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>
            <router-link
              :to="{
                path: '/money_requests',
                query: { id: item.Talabnomalar.id, status: status },
              }"
              class="btn btn-sm btn-primary"
            >
              <i class="fas fa-eye"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <Data_notfound v-if="!talabnoma.data.length" />
  </div>

  <nav
    aria-label="Page navigation example"
    class="mt-1"
    v-if="talabnoma.data.length"
  >
    <ul class="pagination pagination-sm justify-content-end">
      <li class="page-item">
        <select
          class="form-control form-control-sm form-select mx-1"
          v-model="talabnoma.limit"
          @change="get_talabnoma()"
        >
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </li>
      <li class="page-item">
        <button type="button" class="page-link" @click="get_talabnoma(0, 25)">
          <i class="fa-solid fa-rotate"></i>
        </button>
      </li>
      <li class="page-item">
        <button
          class="page-link"
          @click="
            talabnoma.page--;
            get_talabnoma();
          "
          :disabled="talabnoma.page == 0"
        >
          <i class="fa-solid fa-angle-left text-dark"></i>
        </button>
      </li>
      <li class="page-item">
        <select
          class="form-control form-control-sm page_select"
          v-model="talabnoma.page"
          @change="get_talabnoma()"
        >
          <option :value="item - 1" v-for="item in talabnoma.pages" :key="item">
            {{ item }}
          </option>
        </select>
      </li>
      <li class="page-item">
        <button
          class="page-link"
          @click="
            talabnoma.page++;
            get_talabnoma();
          "
          :disabled="talabnoma.page == talabnoma.pages - 1"
        >
          <i class="fa-solid fa-angle-right text-dark"></i>
        </button>
      </li>
    </ul>
  </nav>

  <div class="modal fade" id="create_talabnoma">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Қўшиш</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="talabnoma"
          ></button>
        </div>
        <form @submit.prevent="create_talabnoma()">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <label for="from2">Объектлар: </label>
                <div class="dropdown">
                  <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="select_bank_building2"
                    data-mdb-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    @click="buildings.length ? '' : buildings_get()"
                  >
                    {{ text_building }}
                  </button>
                  <ul
                    class="dropdown-menu border-top-0"
                    aria-labelledby="bank_building"
                  >
                    <li>
                      <input
                        type="search"
                        class="form-control form-control-sm"
                        @keyup="buildings_get()"
                        placeholder="қидириш ..."
                        v-model="search_building"
                      />
                    </li>
                    <li>
                      <ul class="dropdown-items">
                        <li
                          v-for="item in buildings"
                          :key="item"
                          @click="
                            text_building = item.Buildings.name;
                            building_id = item.Buildings.id;
                          "
                        >
                          <a class="dropdown-item">{{ item.Buildings.name }}</a>
                        </li>
                      </ul>
                    </li>
                    <li v-if="buildings != ''">
                      <hr class="dropdown-divider m-0" />
                    </li>
                    <li>
                      <nav
                        aria-label="Page navigation example"
                        class="mt-2"
                        v-if="buildings != ''"
                      >
                        <ul
                          class="pagination pagination-sm justify-content-end"
                        >
                          <li class="page-item">
                            <button
                              type="button"
                              class="page-link"
                              @click="buildings_get()"
                            >
                              <i class="fa-solid fa-rotate"></i>
                            </button>
                          </li>
                          <li class="page-item">
                            <button
                              type="button"
                              class="page-link"
                              @click="
                                page_building--;
                                buildings_get();
                              "
                              :disabled="page_building == 0"
                            >
                              <i class="fa-solid fa-angle-left text-dark"></i>
                            </button>
                          </li>
                          <li class="page-item">
                            <button
                              type="button"
                              class="page-link text-dark"
                              disabled
                            >
                              {{ page_building + 1 }}
                            </button>
                          </li>
                          <li class="page-item">
                            <button
                              type="button"
                              class="page-link"
                              @click="
                                page_building++;
                                buildings_get();
                              "
                              :disabled="page_building == pages_building - 1"
                            >
                              <i class="fa-solid fa-angle-right text-dark"></i>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="talabnoma_disabled"
              :disabled="!building_id"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Header_item from "@/components/header/header_item.vue";
import {
  building_get,
  create_talabnoma,
  get_talabnoma,
} from "@/utils/request/request";
import { catchError, success } from "@/utils/request/handle_api_err";
import Data_notfound from "@/utils/notfound/data_notfound.vue";

export default {
  name: "talabnoma-view",
  data() {
    return {
      role: localStorage.getItem("role"),
      talabnoma: {
        data: [],
        page: 0,
        pages: 0,
        limit: 25,
      },
      from_time: "",
      to_time: "",
      status: false,
      building_id: null,
      buildings: [],
      text_building: "объектлар",
      page_building: 0,
      pages_building: null,
      limit_building: 25,
      search_building: "",
    };
  },
  methods: {
    get_talabnoma() {
      this.$store.dispatch("setLoading", { isLoading: true });

      get_talabnoma(
        this.from_time,
        this.to_time,
        this.role == "taminotchi" ? localStorage.getItem("user_id") : 0,
        0,
        0,
        this.status,
        this.talabnoma.page,
        this.talabnoma.limit
      )
        .then((res) => {
          this.talabnoma.data = res.data.data;
          this.talabnoma.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    buildings_get() {
      this.$store.dispatch("setLoading", { isLoading: true });

      building_get(
        this.page_building,
        this.limit_building,
        this.search_building,
        "start"
      )
        .then((res) => {
          this.buildings = res.data.data;
          this.pages_building = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    create_talabnoma() {
      document.getElementById("talabnoma_disabled").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });
      create_talabnoma(this.building_id)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("talabnoma").click();
          document.getElementById("talabnoma_disabled").disabled = false;
          success(res);
          this.get_talabnoma();
          this.building_id = null;
          this.text_building = "объектлар";
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("talabnoma_disabled").disabled = false;
        });
    },
  },
  created() {
    this.get_talabnoma();
  },
  components: { Header_item, Data_notfound },
};
</script>
