<template>
  <Header_item :name="$route.query.name" />

  <div class="row">
    <div class="col mt-1 d-flex align-items-center">
      <span>Лимит</span>
      <select
        class="form-control form-control-sm ms-2 form-select"
        v-model="limit"
      >
        <option value="25" selected>25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div class="col mt-1 d-flex align-items-center justify-content-end">
      <span>Қидириш: </span>
      <div class="input-group input-group-sm ms-2">
        <input
          type="search"
          v-model="search"
          class="form-control form-control-sm"
        />
      </div>
    </div>
  </div>

  <div class="row cards mt-2">
    <div
      class="col-md-4 col-lg-3 col-sm-6 mt-3"
      v-for="item in workers"
      :key="item"
    >
      <div class="card">
        <div class="card-body p-3">
          <h5>
            <b>
              {{ item.Workers.name }}
            </b>
          </h5>
          <h6 class="text-primary mb-0">
            <b>
              Баланс:
              {{ Intl.NumberFormat().format(item.Workers.balance) }} сўм
            </b>
          </h6>
          <h6 class="text-primary mb-0">
            <b>
              Толаниши керак:
              {{ Intl.NumberFormat().format(item.work_balance) }} сўм
            </b>
          </h6>
          <h6 class="text-primary mb-0" v-if="role == 'prorap'">
            <b> Телефон: +998 {{ item.Workers.phone }} </b>
          </h6>
          <router-link
            v-if="role != 'prorap'"
            :to="{
              path: '/worker_history',
              query: {
                id: item.Workers.id,
                name: item.Workers.name,
                building_id: $route.query.id,
              },
            }"
            class="btn btn-sm btn-primary mt-2 w-100"
          >
            <i class="fas fa-history"></i>
          </router-link>
          <div
            class="d-flex justify-content-center align-items-center zoom"
            v-if="role == 'prorap'"
          >
            <div class="coll mx-1">
              <router-link
                :to="{
                  path: '/worker_history',
                  query: {
                    id: item.Workers.id,
                    name: item.Workers.name,
                    building_id: $route.query.id,
                  },
                }"
                class="btn btn-sm btn-primary w-100"
              >
                <i class="fas fa-history"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <router-link
                :to="{
                  path: '/worker_history',
                  query: {
                    id: item.Workers.id,
                    name: item.Workers.name,
                    building_id: $route.query.id,
                  },
                }"
                class="btn btn-sm btn-primary w-100"
              >
                <i class="fa-solid fa-money-check-dollar"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <button class="btn btn-sm btn-primary w-100">
                <i class="fa-solid fa-money-bill-1-wave"></i>
              </button>
            </div>
            <div class="coll mx-1">
              <div class="btn btn-warning btn-sm">
                <i class="fa-solid fa-pen"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { workers_get } from "@/utils/request/request";
import { catchError } from "@/utils/request/handle_api_err";
import Header_item from "@/components/header/header_item.vue";

export default {
  name: "building_workers",
  data() {
    return {
      limit: 25,
      page: 0,
      pages: null,
      search: "",
      building_id: this.$route.query.id,
      workers: [],
      role: localStorage.getItem("role"),
    };
  },
  methods: {
    get_workers() {
      this.$store.dispatch("setLoading", { isLoading: true });

      workers_get(this.page, this.limit, this.search, this.building_id)
        .then((res) => {
          this.workers = res.data.data;
          this.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
  },
  created() {
    this.get_workers();
  },
  components: { Header_item },
};
</script>

<style lang="scss" scoped>
.col select {
  width: 80px;
  height: 30px;
}

.col .input-group {
  width: 200px;
}

@media (max-width: 532px) {
  .row {
    display: block;
  }
  .row .col .input-group {
    width: 100%;
  }
  .row .col select {
    width: 100%;
  }
}

.card {
  transition: all 0.1s ease-out;
}

.row .card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11), 0 2px 6px rgb(79, 102, 127, 0.48);
}

// .card:hover {
//   transform: translateY(-4px) scale(1.005) translateZ(0);
// }

.row.cards {
  max-height: 70vh;
}
.zoom .coll:hover {
  transform: scale(1.1) translateY(-2px);
}

.zoom .coll {
  margin: 5px 0px;
  transition: all 0.1s linear;
}

.d-flex.justify-content-center.zoom {
  zoom: 92%;
  flex-wrap: wrap;
}
</style>
