import { createRouter, createWebHistory } from "vue-router";
import login from "./../utils/template/login/Login.vue";
import users from "../views/users/users.vue";
import cashier_history from "../views/users/cashier_history.vue";
import building from "../views/buildings/buildings.vue";
import building_statistic from "../views/buildings/building_statistic.vue";
import building_inner from "../views/buildings/building_inner_page.vue";
import workers from "../views/buildings/workers.vue";
import worker_types from "../views/buildings/worker_types.vue";
import proche_sorovlar from "../views/buildings/proche_sorovlar.vue";
import proche_sorov_zakupshik from "../views/buildings/proche_sorov_zakupshik.vue";
// import product from "../views/product/product.vue";
import smeta from "../views/smeta/smeta.vue";
import bank_acoounts from "../views/bank_accounts/bank_accounts.vue";
import transfers_history from "../views/transfer_history/transfer_history.vue";
import transfers_to_users from "../views/transfer_history/money_to_users_from_cashier.vue";
// import transfer_money_to_cashier from "../views/transfer_history/transfer_from_user_to_cashier.vue";
import expences from "../views/expences/expences.vue";
import main_warehouses from "../views/main_warehouses/main_warehouses.vue";
import money_launderers from "../views/money_launderers/money_launderers.vue";
import money_laundered_history from "../views/money_launderers/money_from_money_launderer.vue";
import wallets from "../views/wallets/wallets.vue";
import wallets_history from "../views/wallets/wallets_money_history.vue";
import markets from "../views/markets/markets.vue";
import money_from_bugalter from "../views/transfer_history/money_from_bugalter.vue";
import supply_history from "../views/markets/supply_history.vue";
import supply_request from "../views/schot_request/supply_request.vue";
import supply_accept from "../views/markets/supply_in_warehouse.vue";
import transfered_products from "../views/transfer_history/transfered_products.vue";
import transfered_products_main_w from "../views/transfer_history/transfered_product_main_w.vue";
import building_products from "../views/product/building_product.vue";
import main_warehouse_product from "../views/product/product_in_main_w.vue";
import building_works from "../views/buildings/building_works.vue";
import category from "../views/buildings/category.vue";
import warehouse_product from "../views/product/warehouse_product_admin.vue";
import work_info from "../views/buildings/building_info.vue";
import building_history from "../views/expences/history_admin.vue";
import user_info from "../views/transfer_history/user_info.vue";
import worker_money from "../views/transfer_history/worker_money.vue";
import expense_history_admin from "../views/expences/expense_history_admin.vue";
import money_requests from "../views/money_requests/money_requests.vue";
import talabnoma from "../views/money_requests/talabnoma.vue";
import building_workers from "../views/buildings/building_workers.vue";
import worker_history from "../views/buildings/worker_history.vue";
import menu from "./../utils/main/menu.vue";
import video from "./../views/video/video.vue";
import comment from "./../views/commets/comment.vue";
import money_transfers from "./../views/money_transfers/transfer.vue";
import product_request from "./../views/product/product_request.vue";
import money_request_prorap from "../views/money_requests/money_request_prorap.vue";
import notfound from "./../utils/notfound/notfound.vue";

const role = localStorage.getItem("role");

let routes = [
  // login
  {
    path: "/",
    name: "login",
    component: login,
    meta: { title: "Логин" },
  },
  // not found
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    meta: { requiresAuth: false, title: "Сахифа Топилмади !", icon: "404" },
    component: notfound,
  },
  // users
  {
    path: "/users",
    name: "users",
    meta: {
      requiresAuth: true,
      title: "Ҳодимлар",
      icon: "fa-solid fa-users",
      role: ["admin"],
    },
    component: users,
  },
  // menu
  {
    path: "/menu",
    name: "menu",
    meta: {
      requiresAuth: true,
      title: "Бош меню",
      icon: "fa-solid fa-home",
    },
    component: menu,
  },
  // buildings
  {
    path: "/buildings",
    name: "buildings",
    meta: {
      requiresAuth: true,
      title: "Объектлар",
      icon: "fa-solid fa-building",
      role: ["pto", "admin", "prorap", "zakupshik", "operator", "bugalter"],
    },
    component: building,
  },
  {
    path: "/building",
    name: "building",
    meta: {
      requiresAuth: true,
      title: "Обйект",
      icon: "fa-solid fa-building",
      role: ["admin"],
    },
    component: building_inner,
  },
  {
    path: "/building_statistic",
    name: "building_statistic",
    meta: {
      requiresAuth: true,
      title: "Обйект хисоботи",
      icon: "fa-solid fa-chart-simple",
      role: ["admin"],
    },
    component: building_statistic,
  },
  {
    path: "/warehouse_product",
    name: "warehouse_product",
    meta: {
      requiresAuth: true,
      title: "Обйект материаллари",
      icon: "fa-solid fa-box",
      role: ["admin", "prorap"],
    },
    component: warehouse_product,
  },
  // smeta
  {
    path: "/buildings/smeta",
    name: "smeta",
    meta: {
      requiresAuth: true,
      title: "Смета",
      icon: "fa-solid fa-clipboard-list",
      role: ["pto", "admin", "prorap"],
    },
    component: smeta,
  },
  // workers
  {
    path: "/buildings/workers",
    name: "workers",
    meta: {
      requiresAuth: true,
      title: "Усталар",
      icon: "fa-solid fa-users",
      role: ["prorap"],
    },
    component: workers,
  },
  // proche sorovlar
  {
    path: "/buildings/product_request",
    name: "proche_sorovlar",
    meta: {
      requiresAuth: true,
      title: "Проче сўровлар",
      icon: "fa-solid fa-rectangle-list",
      role: ["prorap", "operator"],
    },
    component: proche_sorovlar,
  },
  // proche sorovlar
  {
    path: "/product_request_zakupshik",
    name: "proche_sorov_zakupshik",
    meta: {
      requiresAuth: true,
      title: "Тақсимланиши керак бўлган материаллар",
      icon: "fa-solid fa-rectangle-list",
      role: ["zakupshik"],
    },
    component: proche_sorov_zakupshik,
  },
  // products
  // {
  //   path: "/products",
  //   name: "products",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Маҳсулотлар",
  //     icon: "fa-solid fa-box",
  //     role: ["pto"],
  //   },
  //   component: product,
  // },
  // bank accounts
  {
    path: "/bank_accounts",
    name: "bank_acoounts",
    meta: {
      requiresAuth: true,
      title: "Банк ҳисоблари",
      icon: "fa-solid fa-wallet",
      role: ["bugalter", "admin"],
    },
    component: bank_acoounts,
  },
  // transfer history
  {
    path: "/transfers_history",
    name: "transfers_history",
    meta: {
      requiresAuth: true,
      title: "Ўтказмалар тарихи",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["bugalter", "admin"],
    },
    component: transfers_history,
  },
  // transfer money to users from cashier
  {
    path: "/transfer_money_to_users",
    name: "transfer_money_to_users",
    meta: {
      requiresAuth: true,
      title: "Ҳодимга пул ўтказиш",
      icon: "fa-solid fa-users",
      role: ["kassir", "prorap"],
    },
    component: transfers_to_users,
  },
  // transfer money to cashier from users
  // {
  //   path: "/transfer_money_to_cashier",
  //   name: "transfer_money_to_cashier",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Пул ўтказмалари",
  //     icon: "fa-solid fa-users",
  //     role: ["taminotchi", "prorap"],
  //   },
  //   component: transfer_money_to_cashier,
  // },
  // expences
  {
    path: "/expenses",
    name: "expences",
    meta: {
      requiresAuth: true,
      title: "Чиқимлар",
      icon: "fa-solid fa-box",
      role: ["taminotchi", "admin", "prorap"],
    },
    component: expences,
  },
  {
    path: "/expense_history",
    name: "expense_history",
    meta: {
      requiresAuth: true,
      title: "Чиқимлар",
      icon: "fa-solid fa-box",
      role: ["admin"],
    },
    component: expense_history_admin,
  },
  // main warehouses
  {
    path: "/main_warehouses",
    name: "main_warehouses",
    meta: {
      requiresAuth: true,
      title: "Базалар",
      icon: "fa-solid fa-warehouse",
      role: ["bazachi", "admin"],
    },
    component: main_warehouses,
  },
  // money launderers
  {
    path: "/money_launderers",
    name: "money_launderers",
    meta: {
      requiresAuth: true,
      title: "Конвертация",
      icon: "fa-solid fa-hand-holding-dollar",
      role: ["kassir", "admin"],
    },
    component: money_launderers,
  },
  // wallets
  {
    path: "/wallets",
    name: "wallets",
    meta: {
      requiresAuth: true,
      title: "Ҳамён",
      icon: "fa-solid fa-wallet",
      role: ["kassir", "admin"],
    },
    component: wallets,
  },
  // wallets
  {
    path: "/markets",
    name: "markets",
    meta: {
      requiresAuth: true,
      title: "Таминот",
      icon: "fa-solid fa-shop",
      role: ["taminotchi"],
    },
    component: markets,
  },
  // money from bugalter
  {
    path: "/money_from_bugalter",
    name: "money_from_bugalter",
    meta: {
      requiresAuth: true,
      title: "Бугалтер ўтказган пуллар",
      icon: "fa-solid fa-money-bill-transfer",
      role: ["kassir"],
    },
    component: money_from_bugalter,
  },
  // money from money launderer history
  {
    path: "/money_launderer_history",
    name: "money_launderer_history",
    meta: {
      requiresAuth: true,
      title: "Ўтказмалар тарихи",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["kassir", "admin"],
    },
    component: money_laundered_history,
  },
  // money from money prorap
  {
    path: "/money_request_prorap",
    name: "money_request_prorap",
    meta: {
      requiresAuth: true,
      title: "Пул сўровлари",
      icon: "fa-solid fa-money-bill-trend-up",
      role: ["prorap"],
    },
    component: money_request_prorap,
  },
  // wallets transfer money history
  {
    path: "/wallet_transfer_history",
    name: "wallet_transfer_history",
    meta: {
      requiresAuth: true,
      title: "Ўтказмалар тарихи",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["kassir", "admin"],
    },
    component: wallets_history,
  },
  {
    path: "/worker_types",
    name: "worker_types",
    meta: {
      requiresAuth: true,
      title: "Ишчи турлари",
      icon: "fa-solid fa-box",
      role: ["operator"],
    },
    component: worker_types,
  },
  {
    path: "/supply_history",
    name: "supply_history",
    meta: {
      requiresAuth: true,
      title: "Таминот тарихи",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["taminotchi"],
    },
    component: supply_history,
  },
  {
    path: "/supply_request",
    name: "supply_request",
    meta: {
      requiresAuth: true,
      title: "Ҳисоб рақамидан пул тўлаш",
      icon: "fa-solid fa-box",
      role: ["bugalter"],
    },
    component: supply_request,
  },
  {
    path: "/supply_accept",
    name: "supply_accept",
    meta: {
      requiresAuth: true,
      title: "Омбордаги материаллар",
      icon: "fa-solid fa-box",
      role: ["omborchi"],
    },
    component: supply_accept,
  },
  {
    path: "/transfered_products",
    name: "transfered_products",
    meta: {
      requiresAuth: true,
      title: "Ўтказилган материаллар тарихи",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["omborchi"],
    },
    component: transfered_products,
  },
  {
    path: "/transfered_products_main_w",
    name: "transfered_products_main_w",
    meta: {
      requiresAuth: true,
      title: "Ўтказилган материаллар тарихи",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["bazachi"],
    },
    component: transfered_products_main_w,
  },
  {
    path: "/building_products",
    name: "building_products",
    meta: {
      requiresAuth: true,
      title: "Объект материаллари",
      icon: "fa-solid fa-box",
      role: ["pto"],
    },
    component: building_products,
  },
  {
    path: "/category",
    name: "category",
    meta: {
      requiresAuth: true,
      title: "Болимлар",
      icon: "fa-solid fa-clipboard-list",
      role: ["pto", "admin", "prorap"],
    },
    component: category,
  },
  {
    path: "/building_works",
    name: "building_works",
    meta: {
      requiresAuth: true,
      title: "Объект ишлари",
      icon: "fa-solid fa-clipboard-list",
      role: ["pto", "admin", "prorap"],
    },
    component: building_works,
  },
  {
    path: "/work_info",
    name: "work_info",
    meta: {
      requiresAuth: true,
      title: "Сарфланган махсулотлар",
      icon: "fa-solid fa-clipboard-list",
      role: ["pto", "admin", "prorap"],
    },
    component: work_info,
  },
  {
    path: "/main_warehouse_product",
    name: "main_warehouse_product",
    meta: {
      requiresAuth: true,
      title: "База материаллари",
      icon: "fa-solid fa-box",
      role: ["bazachi", "admin"],
    },
    component: main_warehouse_product,
  },
  {
    path: "/building_history",
    name: "building_history",
    meta: {
      requiresAuth: true,
      title: "Тарихлар",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["admin", "bugalter"],
    },
    component: building_history,
  },
  {
    path: "/user_info",
    name: "user_info",
    meta: {
      requiresAuth: true,
      title: "Таъминотлар",
      icon: "fa-solid fa-box",
      role: ["kassir"],
    },
    component: user_info,
  },
  {
    path: "/worker_info",
    name: "worker_info",
    meta: {
      requiresAuth: true,
      title: "Устага берилиши керак бўлган пул",
      icon: "fa-solid fa-money-bill",
      role: ["kassir"],
    },
    component: worker_money,
  },
  {
    path: "/cashier_history",
    name: "cashier_history",
    meta: {
      requiresAuth: true,
      title: "Кассир қилган чиқимлар",
      icon: "fa-solid fa-clock-rotate-left",
      role: ["admin"],
    },
    component: cashier_history,
  },
  {
    path: "/money_requests",
    name: "money_requests",
    meta: {
      requiresAuth: true,
      title: "Пул соровлари",
      icon: "fa-solid fa-money-bill-transfer",
      role: ["prorap", "taminotchi", "kassir", "admin"],
    },
    component: money_requests,
  },
  {
    path: "/talabnoma",
    name: "talabnoma",
    meta: {
      requiresAuth: true,
      title: "Талабномалар",
      icon: "fa-solid fa-money-bill-trend-up",
      role: ["prorap", "taminotchi", "kassir", "admin"],
    },
    component: talabnoma,
  },
  {
    path: "/building_workers",
    name: "building_workers",
    meta: {
      requiresAuth: true,
      title: "Объект ишчилари",
      icon: "fa-solid fa-people-group",
      role: ["pto", "admin", "prorap"],
    },
    component: building_workers,
  },
  {
    path: "/worker_history",
    name: "worker_history",
    meta: {
      requiresAuth: true,
      title: "Ишчи тарихи",
      icon: "fa-solid fa-history",
      role: ["pto", "admin", "prorap"],
    },
    component: worker_history,
  },
  {
    path: "/video",
    name: "video",
    meta: {
      requiresAuth: true,
      title: "Видеолар",
      icon: "fa-solid fa-video",
      role: ["prorap"],
    },
    component: video,
  },
  {
    path: "/comment",
    name: "comment",
    meta: {
      requiresAuth: true,
      title: "Ҳабарлар",
      icon: "fa-solid fa-pen",
      role: ["prorap"],
    },
    component: comment,
  },
  {
    path: "/money_transfers",
    name: "money_transfers",
    meta: {
      requiresAuth: true,
      title: "Пул ўтказмалари",
      icon: "fa-solid fa-money-bill-transfer",
      role: ["prorap"],
    },
    component: money_transfers,
  },
  // product
  {
    path: "/product_request",
    name: "product_request",
    meta: {
      requiresAuth: true,
      title: "Маҳсулот сўровлари",
      icon: "fa-solid fa-list-check",
      role: ["prorap"],
    },
    component: product_request,
  },
];

routes = routes.filter((item) => {
  if (item.meta.role) {
    return item.meta.role.find((roleItem) => {
      return roleItem == role;
    });
    // return item.meta.role == role;
  } else {
    return item;
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("access_token") !== null;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next("/");
    }
  }
  next();
});

export default router;
