<template>
  <header_item :name="$route.query.name" />

  <div class="row my-2" v-if="role == 'pto'">
    <div class="col d-flex align-items-center justify-content-center gap-1">
      <button
        class="btn btn-sm btn-secondary"
        data-mdb-toggle="modal"
        data-mdb-target="#category_modal"
      >
        Болим <i class="fa-solid fa-circle-plus"></i>
      </button>
      <router-link
        :to="{
          name: 'building_works',
          query: {
            id: $route.query.building_id,
            name: $route.query.name,
            source_id: $route.query.source_id,
          },
        }"
        class="btn btn-sm btn-secondary"
        v-if="!category.data.length"
      >
        График <i class="fa-solid fa-circle-plus"></i>
      </router-link>
    </div>
  </div>

  <div class="row cards mt-2">
    <div
      class="col-md-4 col-lg-3 col-sm-6 mt-1"
      v-for="item in category.data"
      :key="item"
    >
      <div class="card">
        <div class="card-body">
          <h4>
            <b>
              {{ item.name }}
            </b>
          </h4>
          <div class="row">
            <div class="col" v-if="role != 'prorap'">
              <button
                class="btn btn-sm btn-warning w-100"
                data-mdb-toggle="modal"
                data-mdb-target="#category_modal"
                @click="
                  body = {
                    id: item.id,
                    name: item.name,
                    comment: item.comment,
                    building_id: item.building_id,
                    source_id: item.source_id,
                  }
                "
              >
                <i class="fa-solid fa-edit"></i>
              </button>
            </div>
            <div class="col" v-if="role != 'prorap'">
              <button
                @click="remove(item.id)"
                class="btn btn-sm btn-danger w-100"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <div class="col" v-if="role != 'prorap'">
              <button
                data-mdb-toggle="modal"
                data-mdb-target="#category_modal"
                @click="
                  body.id = item.id;
                  clone = true;
                "
                class="btn btn-sm btn-secondary w-100"
              >
                <i class="fa-solid fa-copy"></i>
              </button>
            </div>
            <div class="col">
              <button
                @click="enter(item.id)"
                class="btn btn-sm btn-primary w-100"
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="category_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ clone ? "Копия" : body.id ? "Ўзгартириш" : "Қўшиш" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="category"
          ></button>
        </div>
        <form @submit.prevent="request_category()">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <label for="nomi"> Номи: </label>
                <input
                  type="text"
                  id="nomi"
                  class="form-control form-control-sm"
                  v-model="body.name"
                  required
                  placeholder="Номи"
                />
              </div>
              <div class="col-10 mx-auto">
                <label for="comment"> Изох: </label>
                <textarea
                  id="comment"
                  class="form-control"
                  v-model="body.comment"
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="category_disabled"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import header_item from "@/components/header/header_item.vue";
import { catchError, success } from "@/utils/request/handle_api_err";
import {
  category_other_works,
  update_category_other_work,
  add_category_other_work,
  other_works,
  remove_category_other_work,
  clone_category_other_work,
} from "@/utils/request/request";
export default {
  components: { header_item },
  name: "building_category",
  data() {
    return {
      role: localStorage.getItem("role"),
      body: {
        id: null,
        name: "",
        comment: "",
        building_id: this.$route.query.building_id,
        source_id: this.$route.query.source_id || 0,
      },
      category: {
        data: [],
        page: 0,
        pages: 0,
        limit: 25,
      },
      clone: false,
    };
  },
  methods: {
    get_category() {
      this.$store.dispatch("setLoading", { isLoading: true });

      category_other_works(
        this.body.building_id,
        this.body.source_id,
        this.category.page,
        this.category.limit
      )
        .then((res) => {
          this.category.data = res.data.data;
          this.category.pages = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    request_category() {
      document.getElementById("category_disabled").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });
      let request = this.clone
        ? clone_category_other_work
        : this.body.id
        ? update_category_other_work
        : add_category_other_work;
      request(this.body)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("category").click();
          document.getElementById("category_disabled").disabled = false;
          success(res);
          this.body = {
            id: null,
            name: "",
            comment: "",
            building_id: this.$route.query.building_id,
            source_id: this.$route.query.source_id || 0,
          };
          this.get_category();
          this.clone = false;
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("category_disabled").disabled = false;
        });
    },
    remove(id) {
      this.$store.dispatch("setLoading", { isLoading: true });
      remove_category_other_work(id)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          success(res);
          this.get_category();
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    enter(id) {
      other_works("", this.body.building_id, "", 0, 0, 25, id, false).then(
        (res) => {
          if (res.data.data.length)
            window.location = `building_works/?id=${this.body.building_id}&name=${this.$route.query.name}&source_id=${id}`;
          else
            window.location = `/category?building_id=${this.body.building_id}&name=${this.$route.query.name}&source_id=${id}`;
        }
      );
    },
  },
  created() {
    this.get_category();
  },
};
</script>

<style scoped>
.card {
  transition: all 0.1s ease-out;
}

.row .card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11), 0 2px 6px rgb(79, 102, 127, 0.48);
}

.card:hover {
  transform: translateY(-4px) scale(1.005) translateZ(0);
}
</style>
