<template>
  <Header_item :name="`Талабнома № ${$route.query.id}`" />

  <div class="row my-2">
    <div class="col d-flex align-items-center justify-content-center">
      <button
        class="btn btn-sm btn-secondary"
        data-mdb-toggle="modal"
        data-mdb-target="#create_request"
        v-if="role == 'taminotchi' || role == 'prorap'"
      >
        Пул соров <i class="fa-solid fa-circle-plus"></i>
      </button>
    </div>
  </div>

  <div class="table-responsive mt-2">
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <td>№</td>
          <td>Сумма</td>
          <td>Тасдиклаган сумма</td>
          <td>Изох</td>
          <td
            v-if="$route.query.status !== 'true' && role !== 'taminotchi'"
          ></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in money_request.data" :key="item">
          <td>{{ i + 1 }}</td>
          <td>{{ Intl.NumberFormat().format(item.money) }} сум</td>
          <td>{{ Intl.NumberFormat().format(item.confirmed_money) }} сум</td>
          <td>{{ item.comment }}</td>
          <td
            v-if="
              $route.query.status !== 'true' &&
              role !== 'taminotchi' &&
              role !== 'prorap'
            "
          >
            <button
              class="btn btn-sm btn-warning"
              data-mdb-toggle="modal"
              data-mdb-target="#update_money_request"
              @click="
                update_form = {
                  id: item.id,
                  confirmed_money: item.confirmed_money,
                }
              "
            >
              <i class="fas fa-edit"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot
        v-if="
          $route.query.status !== 'true' &&
          role !== 'taminotchi' &&
          role !== 'prorap'
        "
      >
        <tr>
          <td colspan="5">
            <button
              class="btn btn-sm btn-success w-100"
              @click="confirm_request($route.query.id)"
            >
              <i class="fas fa-circle-check"></i>
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
    <Data_notfound v-if="!money_request.data.length" />
  </div>

  <div class="modal fade" id="update_money_request">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ўзгартириш</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="money_request"
          ></button>
        </div>
        <form @submit.prevent="update_money_request">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <label for="confirmed_money">
                  Сумма:
                  <span class="text-primary"
                    >{{
                      new Intl.NumberFormat().format(
                        update_form.confirmed_money
                      )
                    }}
                    сўм</span
                  ></label
                >
                <div class="input-group input-group-sm">
                  <input
                    id="confirmed_money"
                    type="number"
                    step="any"
                    class="form-control"
                    v-model="update_form.confirmed_money"
                    min="0"
                    required
                  />
                  <span class="input-group-text bg-white">сўм</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="request_disabled"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="create_request">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Сўров қўшиш</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
            id="request"
          ></button>
        </div>
        <form @submit.prevent="create_request()">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <label for="balance1">
                  Сумма:
                  <span class="text-primary"
                    >{{
                      new Intl.NumberFormat().format(create_form.money)
                    }}
                    сўм</span
                  ></label
                >
                <div class="input-group input-group-sm">
                  <input
                    id="balance1"
                    type="number"
                    step="any"
                    class="form-control"
                    v-model="create_form.money"
                    min="0"
                    required
                  />
                  <span class="input-group-text bg-white">сўм</span>
                </div>
              </div>
              <div class="col-10 mx-auto">
                <label for="comment1"> Изох: </label>
                <textarea
                  id="comment1"
                  class="form-control"
                  v-model="create_form.comment"
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-outline-primary btn-sm"
              id="request_disabled"
            >
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              data-mdb-dismiss="modal"
            >
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Header_item from "@/components/header/header_item.vue";
import {
  get_money_requests,
  confirm_talabnoma,
  update_money_request,
  create_money_request,
} from "@/utils/request/request";
import { catchError, success } from "@/utils/request/handle_api_err";
import Data_notfound from "@/utils/notfound/data_notfound.vue";

export default {
  name: "money-request",
  data() {
    return {
      role: localStorage.getItem("role"),
      money_request: {
        data: [],
        page: 0,
        pages: 0,
        limit: 25,
      },
      from_time: "",
      to_time: "",
      status: true,
      update_form: {
        id: 0,
        confirmed_money: 0,
      },
      create_form: {
        money: null,
        comment: "",
        talabnoma_id: this.$route.query.id,
      },
    };
  },
  methods: {
    get_request() {
      this.$store.dispatch("setLoading", { isLoading: true });

      get_money_requests(this.$route.query.id)
        .then((res) => {
          this.money_request.data = res.data;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    confirm_request(id) {
      this.$store.dispatch("setLoading", { isLoading: true });

      confirm_talabnoma(id)
        .then(() => {
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    update_money_request() {
      this.$store.dispatch("setLoading", { isLoading: true });
      document.getElementById("request_disabled").disabled = true;
      update_money_request(this.update_form)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("money_request").click();
          document.getElementById("request_disabled").disabled = false;
          success(res);
          this.get_request();
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("request_disabled").disabled = false;
        });
    },
    create_request() {
      document.getElementById("request_disabled").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });
      create_money_request(this.create_form)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("request").click();
          document.getElementById("request_disabled").disabled = false;
          success(res);
          this.get_request();
          this.create_form = {
            money: null,
            comment: "",
            talabnoma_id: this.$route.query.id,
          };
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("request_disabled").disabled = false;
        });
    },
  },
  created() {
    this.get_request();
  },
  components: { Header_item, Data_notfound },
};
</script>
